<template>
  <v-container>
    <div style="width: 100%;max-width: 640px;" class="mx-auto">
      <v-card elevation="7" class="rounded-lg mb-6">
        <v-img height="256" src="/pictures/programming-school.jpg" gradient="to bottom, #0b243580, #2980b980"></v-img>
        <v-card-title>STREAM教室の申し込み</v-card-title>
        <v-card-text>
          下記の手順にしたがって、利用規約に同意の上、会員登録申し込みに必要な情報を入力してください。
        </v-card-text>
      </v-card>
      <v-stepper v-model="stepper.step" alt-labels class="rounded-lg">
        <v-stepper-header>
          <v-stepper-step
            :complete="stepper.step > 1"
            :step="1"
            edit-icon="$complete"
            :editable="stepper.step > 1"
          >
            コース情報
          </v-stepper-step>
          <v-divider/>
          <v-stepper-step
            :complete="stepper.step > 2"
            :step="2"
            edit-icon="$complete"
            :editable="stepper.step > 2"
          >
            お支払い情報
          </v-stepper-step>
          <v-divider/>
          <v-stepper-step
            :complete="stepper.step > 3"
            :step="3"
            edit-icon="$complete"
            :editable="stepper.step > 3"
          >
            利用規約
          </v-stepper-step>
          <v-divider/>
          <v-stepper-step
            :complete="stepper.step > 4"
            :step="4"
            edit-icon="$complete"
            :editable="stepper.step > 4"
          >
            ご確認
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form ref="form_step1">
              <div class="title">コース情報の入力</div>
              <div class="caption">*がついた項目は入力必須です</div>
              <div class="subtitle-1 mt-8">受講可能な曜日・時間*</div>
              <div class="caption">
                1クラスあたりの受講者数調整のため、受講可能な曜日・時間をできるだけ複数ご選択ください
              </div>
              <v-select
                v-for="i in [0,1,2]" :key="i"
                v-model="constantSchool.wishDatetime[i]"
                :items="datetimes"
                :label="'第'+(i+1)+'希望*'"
                :rules="[validationRules.wishDatetime(i)]"
                @input="$refs.form_step1.validate()"
                persistent-hint
              ></v-select>
              <div class="subtitle-1 mt-8">受講コース</div>
               <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        コース名前
                      </th>
                      <!--
                      <th class="text-left">
                        STREAM
                      </th>
                      -->
                      <th class="text-left">
                        スタンダード
                      </th>
                      <th class="text-left">
                        ミニマム
                      </th>
                      <th class="text-left">
                        エントリー
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in questions"
                      :key="item.name"
                    >
                      <td>{{ item.question }}</td>
                      <!--<td>{{ item.stream }}</td>-->
                      <td>{{ item.standard }}</td>
                      <td>{{ item.minimum }}</td>
                      <td>{{ item.entry }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div class="caption mt-8">
                1つのコースは1ヶ月を基本単位としていますが、ご希望に応じて変更することができます。<br/>
                変更は翌月に反映されます。
              </div>
              
              <v-select
                v-model="constantSchool.courseId"
                :items="courseSelects"
                label="受講コース"
                persistent-hint
              ></v-select>

              <div class="subtitle-1 mt-8">価格</div>
              <div class="caption">
                毎月1日に、4週分まとめてお支払いいただきます。
              </div>
              <v-simple-table v-if="selectedCourse">
                <template v-slot:default>
                  <tbody>
                  <tr>
                    <td width="40%">入会費</td>
                    <td><span class="font-large">{{ Math.floor(selectedCourse.registrationFee * (1 + TAX_RATE)).toLocaleString() }}</span>円（税込）</td>
                  </tr>
                  <tr>
                    <td>受講費</td>
                    <td>
                      <div>月々<span class="font-large">{{ Math.floor(selectedCourse.fee * 4 * (1 + TAX_RATE)).toLocaleString() }}</span>円（税込）</div>
                      <!--<div><span class="font-small">1週間あたり</span>{{ (selectedCourse.fee * (1 + TAX_RATE)).toLocaleString() }}<span class="font-small">円（税込）</span></div> -->
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-row class="mt-6 mb-8">
                <v-col>
                  <v-btn color="primary" rounded outlined width="100%" @click="goToNextStep">次へ</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>
          <v-stepper-content step="2">
            <div class="title">お支払い情報の入力</div>
            <div v-if="UserReadonlyData.card">
              <div class="caption">異なるカード情報でお支払いを希望する場合は、「アカウント設定」から「カード情報の変更」を行ってください</div>
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                  <tr>
                    <td width="40%">カード情報</td>
                    <td>**** **** **** {{ UserReadonlyData.card.last4 }}</td>
                  </tr>
                  <tr>
                    <td>カード有効期限</td>
                    <td>{{ UserReadonlyData.card.exp_month }} / {{ UserReadonlyData.card.exp_year }}</td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
            <div v-else class="ma-4">
              <PayJpArea text="カード情報の登録" @onTokenCreated="setCardToken"/>
            </div>
            <v-alert type="info" dense outlined class="mt-2">信頼性と安全性の高い<a href="https://pay.jp" target="_blank">Pay.jp</a>を利用しています。</v-alert>
            <v-row class="mt-6 mb-8">
              <v-col>
                <v-btn rounded width="100%" depressed @click="stepper.step -= 1">戻る</v-btn>
              </v-col>
              <v-col>
                <v-btn color="primary" rounded outlined width="100%" @click="goToNextStep" :disabled="!(this.cardToken || this.UserReadonlyData.card)">次へ</v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-content step="3">
            <v-form ref="form_step3">
              <div class="title">利用規約への同意</div>
              <v-card outlined class="mt-8">
                <div class="terms">
                  <v-card-text>
継続プログラミング教室の利用規約<br/>
<br/>
この利用規約（以下，「本規約」といいます。）は，合同会社学生ギルド（以下，「当社」といいます。）が継続プログラミング教室サービス（以下，「本サービス」といいます。）の利用条件を定めるものです。
登録ユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本サービスをご利用いただきます。<br/>
<br/>
第1条（適用）<br/>
1. 本規約は，ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。<br/>
2. 当社は本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下，「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。<br/>
3. 本規約の規定が前条の個別規定の規定と矛盾する場合には，個別規定において特段の定めなき限り，個別規定の規定が優先されるものとします。<br/>
<br/>
第2条（利用登録）<br/>
1. 本サービスにおいては，登録希望者が本規約に同意の上，当社の定める方法によって利用登録を申請し，当社がこれを承認することによって，利用登録が完了するものとします。<br/>
2. 当社は，利用登録の申請者に以下の事由があると判断した場合，利用登録の申請を承認しないことがあり，その理由については一切の開示義務を負わないものとします。<br/>
  1. 利用登録の申請に際して虚偽の事項を届け出た場合<br/>
  2. 本規約に違反したことがある者からの申請である場合<br/>
  3. その他，当社が利用登録を相当でないと判断した場合<br/>
<br/>
第3条（利用料金および支払方法）<br/>
1. ユーザーは，本サービスの有料部分の対価として，当社が別途定め，本ウェブサイトに表示する利用料金を，当社が指定する方法により支払うものとします。<br/>
2. ユーザーが利用料金の支払を遅滞した場合には，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止することができるものとします。<br/>
3. ユーザーが利用料金の支払
<br/>
第4条（禁止事項）<br/>
ユーザーは，本サービスの利用にあたり，以下の行為をしてはなりません。<br/>
1. 法令または公序良俗に違反する行為<br/>
2. 犯罪行為に関連する行為<br/>
3. 本サービスの内容等，本サービスに含まれる著作権，商標権ほか知的財産権を侵害する行為<br/>
4. 当社，ほかのユーザー，またはその他第三者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為<br/>
5. 本サービスによって得られた情報を商業的に利用する行為<br/>
6. 当社のサービスの運営を妨害するおそれのある行為<br/>
7. 不正アクセスをし，またはこれを試みる行為<br/>
8. 他のユーザーに関する個人情報等を収集または蓄積する行為<br/>
9. 不正な目的を持って本サービスを利用する行為<br/>
10. 本サービスの他のユーザーまたはその他の第三者に不利益，損害，不快感を与える行為<br/>
11. 他のユーザーに成りすます行為<br/>
12. 当社が許諾しない本サービス上での宣伝，広告，勧誘，または営業行為<br/>
13. 面識のない異性との出会いを目的とした行為<br/>
14. 当社のサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為<br/>
15. その他，当社が不適切と判断する行為<br/>
<br/>
第5条（本サービスの提供の停止等）<br/>
1. 当社は，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。<br/>
  1. 本サービスにかかるコンピュータシステムの保守点検または更新を行う場合<br/>
  2. 地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合<br/>
  3. コンピュータまたは通信回線等が事故により停止した場合<br/>
  4. その他，当社が本サービスの提供が困難と判断した場合<br/>
2. 当社は，本サービスの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害についても，一切の責任を負わないものとします。<br/>
<br/>
第6条（利用制限および登録抹消）<br/>
1. 当社は，ユーザーが以下のいずれかに該当する場合には，事前の通知なく，ユーザーに対して，本サービスの全部もしくは一部の利用を制限し，またはユーザーとしての登録を抹消することができるものとします。<br/>
  1. 本規約のいずれかの条項に違反した場合<br/>
  2. 登録事項に虚偽の事実があることが判明した場合<br/>
  3. 料金等の支払債務の不履行があった場合<br/>
  4. 当社からの連絡に対し，一定期間返答がない場合<br/>
  5. 本サービスについて，最終の利用から一定期間利用がない場合<br/>
  6. その他，当社が本サービスの利用を適当でないと判断した場合<br/>
2. 当社は，本条に基づき当社が行った行為によりユーザーに生じた損害について，一切の責任を負いません。<br/>
<br/>
第7条（退会）<br/>
ユーザーは，当社の定める退会手続により，本サービスから退会できるものとします。<br/>
退会した月に支払がすでになされた利用料金については，日割りにせず，返金されないものとします。<br/>
<br/>
第8条（保証の否認および免責事項）<br/>
1. 当社は，本サービスに事実上または法律上の瑕疵（安全性，信頼性，正確性，完全性，有効性，特定の目的への適合性，セキュリティなどに関する欠陥，エラーやバグ，権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。<br/>
2. 当社は，本サービスに起因してユーザーに生じたあらゆる損害について一切の責任を負いません。ただし，本サービスに関する当社とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合，この免責規定は適用されません。<br/>
3. 前項ただし書に定める場合であっても，当社は，当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（当社またはユーザーが損害発生につき予見し，または予見し得た場合を含みます。）について一切の責任を負いません。また，当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害の賠償は，ユーザーから当該損害が発生した月に受領した利用料の額を上限とします。<br/>
4. 当社は，本サービスに関して，ユーザーと他のユーザーまたは第三者との間において生じた取引，連絡または紛争等について一切責任を負いません。<br/>
<br/>
第9条（サービス内容の変更等）<br/>
当社は，ユーザーに通知することなく，本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし，これによってユーザーに生じた損害について一切の責任を負いません。<br/>
<br/>
第10条（利用規約の変更）<br/>
当社は，必要と判断した場合には，ユーザーに通知することなくいつでも本規約を変更することができるものとします。なお，本規約の変更後，本サービスの利用を開始した場合には，当該ユーザーは変更後の規約に同意したものとみなします。<br/>
第11条（個人情報の取扱い）<br/>
当社は，本サービスの利用によって取得する個人情報については，当社「プライバシーポリシー」に従い適切に取り扱うものとします。<br/>
<br/>
第12条（通知または連絡）<br/>
ユーザーと当社との間の通知または連絡は，当社の定める方法によって行うものとします。当社は,ユーザーから,当社が別途定める方式に従った変更届け出がない限り,現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い,これらは,発信時にユーザーへ到達したものとみなします。<br/>
第13条（権利義務の譲渡の禁止）<br/>
ユーザーは，当社の書面による事前の承諾なく，利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し，または担保に供することはできません。<br/>
<br/>
第14条（準拠法・裁判管轄）<br/>
1. 本規約の解釈にあたっては，日本法を準拠法とします。<br/>
2. 本サービスに関して紛争が生じた場合には，当社の本店所在地を管轄する裁判所を専属的合意管轄とします。<br/>
以上<br/>
                  </v-card-text>
                </div>
              </v-card>
              <v-checkbox label="利用規約に同意する"
                          class="ml-4"
                          v-model="agreeTerms"
                          :rules="[validationRules.requiredAgree]"
              ></v-checkbox>
              <v-row class="mt-6 mb-8">
                <v-col>
                  <v-btn rounded width="100%" depressed @click="stepper.step -= 1">戻る</v-btn>
                </v-col>
                <v-col>
                  <v-btn color="primary" rounded outlined width="100%" @click="goToNextStep">次へ</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>
          <v-stepper-content step="4">
            <div class="title">ご確認</div>
            <div class="subtitle-1 mt-8">受講曜日・時間</div>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                <tr v-for="i in [0, 1, 2]" :key="i">
                  <td width="40%">第{{ i+1 }}希望</td>
                  <td>{{ datetimes.find(datetime => datetime.value === constantSchool.wishDatetime[i]).text }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div class="subtitle-1 mt-8">受講コース・価格</div>
            <v-simple-table v-if="selectedCourse">
              <template v-slot:default>
                <tbody>
                <tr>
                  <td width="40%">受講コース</td>
                  <td>{{ selectedCourse.name }}</td>
                </tr>
                <tr>
                  <td width="40%">入会費</td>
                  <td>{{ Math.floor(selectedCourse.registrationFee * (1 + TAX_RATE)).toLocaleString() }}円(税込)</td>
                </tr>
                <tr>
                  <td width="40%">受講費</td>
                  <td>月々{{ Math.floor(selectedCourse.fee * 4 * (1 + TAX_RATE)).toLocaleString() }}円(税込)</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div class="subtitle-1 mt-8">ご請求額</div>
            <v-simple-table v-if="selectedCourse">
              <template v-slot:default>
                <tbody>
                <tr>
                  <td width="40%">入会費</td>
                  <td>{{ selectedCourse.registrationFee.toLocaleString() }}円</td>
                </tr>
                <tr>
                  <td width="40%">消費税等</td>
                  <td>{{ (sumAmount * TAX_RATE).toLocaleString() }}円</td>
                </tr>
                <tr>
                  <td width="40%">合計金額（消費税等込）</td>
                  <td><span class="font-weight-bold font-large">{{ Math.floor(sumAmount * (1 + TAX_RATE)).toLocaleString() }}円</span></td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>

            <v-row class="mt-6 mb-8">
              <v-col>
                <v-btn rounded width="100%" depressed @click="stepper.step -= 1">戻る</v-btn>
              </v-col>
              <v-col>
                <v-btn color="primary" rounded depressed width="100%" :loading="signingUp" @click="signUp">この内容で申し込む</v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
  </v-container>
</template>

<script>

  import validationRules from '../../../utils/validationRules'
  import PayJpArea from '../../../components/PayJpArea'
  export default {
    components: {
      PayJpArea
    },
    data () {
      return {
        questions: [
          {
            question: '授業数',
            stream: "月8回",
            standard: "月8回",
            minimum: "月4回",
            entry: "月3回",
          },
          {
            question: 'オンラインフィールドワーク',
            stream: "あり",
            standard: "あり",
            minimum: "あり",
            entry: "なし",
          },
          // {
          //   question: '定期懇談会',
          //   stream: "○",
          //   standard: "☓",
          //   minimum: "☓",
          //   entry: "☓",
          // },
          {
            question: '月額（税別）',
            stream: "23,000円",
            standard: "18,000円",
            minimum: "11,000円",
            entry: "5,500円",
          },
          {
            question: '講師',
            stream: "コンテスト経験等を持つ学生エンジニア",
            standard: "コンテスト経験等を持つ学生エンジニア",
            minimum: "コンテスト経験等を持つ学生エンジニア",
            entry: "高校生（本教室卒業生）",
          },
        ],


        constantSchool: {
          wishDatetime: [
            null,
            null,
            null
          ],
          courseId: null
        },
        datetimes: [
          {text: '指定しない', value: null},
        ],
        courses: {},
        validationRules: Object.assign({
          wishDatetime: number => value => {
            if (value === null) {
              for (let i=number+1;i<3;i++)
                if (this.constantSchool.wishDatetime[i] !== null) return "第"+(i+1)+"希望が指定されています"
            } else {
              for (let i=0;i<number;i++)
                if (this.constantSchool.wishDatetime[i] === null) return "第"+(i+1)+"希望を指定してください"
              for (let i=0;i<3;i++)
                if (i !== number && this.constantSchool.wishDatetime[i] === value) return "第"+(i+1)+"希望と異なる日時にしてください"
            }
            return true
          },
        }, validationRules),
        agreeTerms: false,
        cardToken: null,
        stepper: {
          step: 1,
        },
        signingUp: false,
        TAX_RATE: 0.1
      }
    },
    computed: {
      userAuth: function () { return this.$store.state.user.auth },
      userData: function () { return this.$store.state.user.data },
      UserReadonlyData: function () { return this.$store.state.user.readonlyData },
      userFetched: function () { return this.$store.state.user.fetched },
      datetimesWithoutNull: function () { return this.datetimes.slice(1, this.datetimes.length) },
      selectedCourse: function () { return this.courses[this.constantSchool.courseId] },
      courseSelects: function () { return Object.entries(this.courses).map(([key, value]) => ({value: key, text: `${value.name}`})) },
      sumAmount: function () { return this.selectedCourse.registrationFee }
    },
    created () {
      // console.log("コース情報を取得する")
      Promise.all([
        this.$firebase.firestore().collection("constantSchoolCourses").get()
          .then((docs) => {
            docs.forEach((doc) => {
              this.$set(this.courses, doc.id, doc.data())
            })
            this.constantSchool.courseId = this.courseSelects[0].value
          }),
        this.$firebase.firestore().collection("constantSchoolDatetimes").get()
          .then((docs) => {
            docs.forEach((doc) => {
              this.datetimes.push({ value: doc.id, text: doc.data().text })
            })
            
            const daysOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
            this.datetimes.sort((a, b) => {
              const val1 = (a.value === null ? 0 : daysOfWeek.indexOf(a.value.slice(0, 3)));
              const val2 = (b.value === null ? 0 : daysOfWeek.indexOf(b.value.slice(0, 3)));
              return val1 - val2;
              });
            console.log(this.datetimes);
          })
      ]).catch((error) => {
        console.error("Error: ", error)
        this.$store.commit('notification/notify', { text: 'データの取得に失敗しました。しばらくしてからもう一度お試しください。', type: 'error' }, { root: true })
      })
    },
    methods: {
      setCardToken: function (token) {
        console.log(token)
        this.cardToken = token
      },
      goToNextStep: function () {
        if (this.stepper.step == 1) {
          if (this.$refs.form_step1.validate())
            this.stepper.step ++
        } else if (this.stepper.step == 2) {
          if (this.cardToken || this.UserReadonlyData.card)
            this.stepper.step ++
        } else if (this.stepper.step == 3) {
          if (this.$refs.form_step3.validate())
            this.stepper.step ++
        }
      },
      signUp: function () {
        this.signingUp = true
        this.$store.dispatch("constantSchoolCourse/signUp", { constantSchool: this.constantSchool, token: this.cardToken }).then(() => {
          if (this.$route.path !== '/account'){
            this.$router.push("/account")
          }
        }).catch((errorCode) => {
          console.log("Error: ", errorCode)
        }).finally(() => {
          this.signingUp = false
        })
      }
    }
  }


</script>
<style scoped lang="scss">
  .terms {
    height: 200px;
    white-space: pre-line;
    overflow-y: auto;
  }
  .font-large {
    font-size: 1.5em;
  }
  .font-small {
    font-size: 0.75em;
  }
</style>
