import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

import ja from 'vuetify/es5/locale/ja.js'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { ja },
    current: 'ja',
  },
  theme: {
    themes: {
      light: {
        primary: colors.amber.darken3,
        secondary: colors.red.base,
        accent: colors.indigo.base,
      }
    },
  },
});
