<template>
  <v-container>
    <div style="width: 100%;max-width: 640px;" class="mx-auto">
      <v-card elevation="7" class="rounded-lg mb-6">
        <v-img height="256" src="/pictures/events.jpg" gradient="to bottom, #0b243580, #2980b980"></v-img>
        <v-card-title>{{ event.name }}のキャンセル</v-card-title>
        <v-card-text>
          下記の手順にしたがって、イベント参加キャンセルに必要な情報を入力してください。
        </v-card-text>
      </v-card>
      <v-stepper v-model="stepper.step" alt-labels class="rounded-lg">
        <v-stepper-header>
          <v-stepper-step
            :complete="stepper.step > 1"
            :step="1"
            edit-icon="$complete"
            :editable="stepper.step > 1"
          >
            イベント情報
          </v-stepper-step>
          <v-divider/>
          <v-stepper-step
            :complete="stepper.step > 2"
            :step="2"
            edit-icon="$complete"
            :editable="stepper.step > 2"
          >
            ご確認
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form ref="form_step1">
              <div class="title">イベント情報</div>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="text-h5 mb-1">
                    {{ event.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ "日時: " + event.startDatetime.toDate().getFullYear() + "年" + (event.startDatetime.toDate().getMonth() + 1) + "月" + event.startDatetime.toDate().getDate() + "日 " + ('0' + event.startDatetime.toDate().getHours()).slice(-2)  + ":" + ('0' + event.startDatetime.toDate().getMinutes()).slice(-2) + " ~ " + (event.startDatetime.toDate().getFullYear() === event.endDatetime.toDate().getFullYear() ? "" : event.endDatetime.toDate().getFullYear() + "年") + (event.endDatetime.toDate().getMonth() + 1) + "月" + event.endDatetime.toDate().getDate() + "日 " + ('0' + event.endDatetime.toDate().getHours()).slice(-2)  + ":" + ('0' + event.endDatetime.toDate().getMinutes()).slice(-2) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <div class="subtitle-1 mt-8">払い戻し額</div>
              <div class="caption">
                キャンセル完了と同時に以下の費用が払い戻されます。
              </div>
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                  <tr>
                    <td width="40%">参加費</td>
                    <td><span class="font-large">{{ Math.floor(event.fee * (1 + TAX_RATE)).toLocaleString() }}</span>円（税込）</td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-row class="mt-6 mb-8">
                <v-col>
                  <v-btn color="primary" rounded outlined width="100%" @click="goToNextStep">次へ</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>
          <v-stepper-content step="2">
            <div class="title">ご確認</div>
            <div class="subtitle-1 mt-8">キャンセルするイベント</div>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                <tr>
                  <td width="40%">イベント名</td>
                  <td>{{ event.name }}</td>
                </tr>
                <tr>
                  <td width="40%">日時</td>
                  <td>
                    {{ event.startDatetime.toDate().getFullYear() + "年" + (event.startDatetime.toDate().getMonth() + 1) + "月" + event.startDatetime.toDate().getDate() + "日 " + ('0' + event.startDatetime.toDate().getHours()).slice(-2)  + ":" + ('0' + event.startDatetime.toDate().getMinutes()).slice(-2) + " ~ " + (event.startDatetime.toDate().getFullYear() === event.endDatetime.toDate().getFullYear() ? "" : event.endDatetime.toDate().getFullYear() + "年") + (event.endDatetime.toDate().getMonth() + 1) + "月" + event.endDatetime.toDate().getDate() + "日 " + ('0' + event.endDatetime.toDate().getHours()).slice(-2)  + ":" + ('0' + event.endDatetime.toDate().getMinutes()).slice(-2) }}
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div class="subtitle-1 mt-8">払い戻し額</div>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                <tr>
                  <td width="40%">参加費</td>
                  <td>{{ event.fee.toLocaleString() }}円</td>
                </tr>
                <tr>
                  <td width="40%">消費税等</td>
                  <td>{{ (event.fee * TAX_RATE).toLocaleString() }}円</td>
                </tr>
                <tr>
                  <td width="40%">合計金額（消費税等込）</td>
                  <td><span class="font-weight-bold font-large">{{ Math.floor(event.fee * (1 + TAX_RATE)).toLocaleString() }}円</span></td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>

            <v-row class="mt-6 mb-8">
              <v-col>
                <v-btn rounded width="100%" depressed @click="stepper.step -= 1">戻る</v-btn>
              </v-col>
              <v-col>
                <v-btn color="error" rounded depressed width="100%" :loading="cancelling" @click="cancel">この内容でキャンセルする</v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
  </v-container>
</template>

<script>

  export default {
    data () {
      return {

        stepper: {
          step: 1,
        },
        cancelling: false,
        TAX_RATE: 0.1
      }
    },
    computed: {
      userAuth: function () { return this.$store.state.user.auth },
      userData: function () { return this.$store.state.user.data },
      UserReadonlyData: function () { return this.$store.state.user.readonlyData },
      userFetched: function () { return this.$store.state.user.fetched },

      eventData: function () { return this.$store.state.event.events },
      eventFetched: function () { return this.$store.state.event.fetched },
      eventId: function () { return this.$route.params.event_id },
			event: function () { return this.eventData?.find((event) => { return this.eventId === event.id }) },
			userEvent: function () { return this.$store.getters["user/userEvent"](this.eventId) }
    },
    created () {
      if (new Date() < this.event.applyEndDatetime.toDate() && (typeof(this.userEvent) === 'undefined' || this.userEvent.cancelDatetime !== null)) 
        this.$router.push("/events/" + this.event.id)
    },
    methods: {
      goToNextStep: function () {
        if (this.stepper.step == 1) {
          if (this.$refs.form_step1.validate())
            this.stepper.step ++
        } else if (this.stepper.step == 2) {
          if (this.$refs.form_step2.validate())
            this.stepper.step ++
        }
      },
      cancel: function () {
        this.cancelling = true
        this.$store.dispatch("event/cancel", { eventId: this.eventId }).then(() => {
          if (this.$route.path !== '/account'){
            this.$router.push("/account")
          }
        }).catch((errorCode) => {
          console.log("Error: ", errorCode)
        }).finally(() => {
          this.cancelling = false
        })
      }
    }
  }

</script>
<style scoped lang="scss">
  .terms {
    height: 200px;
    white-space: pre-line;
    overflow-y: auto;
  }
  .font-large {
    font-size: 1.5em;
  }
  .font-small {
    font-size: 0.75em;
  }
</style>
