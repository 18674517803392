<template>
  <v-container>
    <div style="width: 100%;max-width: 640px;" class="mx-auto">
      <v-card elevation="7" class="rounded-lg mb-6">
        <v-card-title>新規会員登録</v-card-title>
        <v-card-text>
          下記の手順にしたがって、利用規約に同意の上、会員登録申し込みに必要な情報を入力してください。
        </v-card-text>
      </v-card>
      <v-stepper v-model="stepper.step" alt-labels class="rounded-lg">
        <v-stepper-header>
          <v-stepper-step
            :complete="stepper.step > 1"
            :step="1"
            edit-icon="$complete"
            :editable="stepper.step > 1"
          >
            会員情報
          </v-stepper-step>
          <v-divider/>
          <v-stepper-step
            :complete="stepper.step > 2"
            :step="2"
            edit-icon="$complete"
            :editable="stepper.step > 2"
          >
            保護者情報
            <small>未成年のみ</small>
          </v-stepper-step>
          <v-divider/>
          <v-stepper-step
            :complete="stepper.step > 3"
            :step="3"
            edit-icon="$complete"
            :editable="stepper.step > 3"
          >
            利用規約
          </v-stepper-step>
          <v-divider/>
          <v-stepper-step
            :complete="stepper.step > 4"
            :step="4"
            edit-icon="$complete"
            :editable="stepper.step > 4"
          >
            ご確認
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form ref="form_step1">
              <div class="title">会員情報の入力</div>
              <div class="caption">プログラミング教室等の受講を希望される方は、ご本人の情報を入力してください。</div>
              <div class="caption">*がついた項目は入力必須です</div>
              <div class="subtitle-1 mt-8">氏名</div>
              <v-row>
                <v-col>
                  <v-text-field
                    label="名字*"
                    v-model="student.profile.familyName"
                    placeholder="山田"
                    :rules="[validationRules.required]"
                  ></v-text-field>
                  <v-text-field
                    label="名字（カナ）*"
                    v-model="student.profile.familyNameKana"
                    placeholder="ヤマダ"
                    hint="全角カナで入力ください"
                    :rules="[validationRules.required, validationRules.kana]"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="名前*"
                    v-model="student.profile.firstName"
                    placeholder="太郎"
                    :rules="[validationRules.required]"
                  ></v-text-field>
                  <v-text-field
                    label="名前（カナ）*"
                    v-model="student.profile.firstNameKana"
                    placeholder="タロウ"
                    hint="全角カナで入力ください"
                    :rules="[validationRules.required, validationRules.kana]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <div class="subtitle-1 mt-8">生年月日と性別</div>
              <v-row>
                <v-col>
                  <v-menu
                    v-model="studentProfileBirthDateMenu.show"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="studentProfileBirthDateFormatted"
                        label="生年月日*"
                        readonly
                        v-on="on"
                        :rules="[validationRules.required]"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="studentProfileBirthDatePicker"
                      v-model="student.profile.birthDate"
                      :max="new Date().toISOString().substr(0, 10)"
                      min="1950-01-01"
                      @input="studentProfileBirthDateMenu.show = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col>
                  <v-select label="性別*"
                            v-model="student.profile.gender"
                            :items="['男', '女', '無回答']"
                            :rules="[validationRules.required]"
                  ></v-select>
                </v-col>
              </v-row>
              <div class="subtitle-1 mt-8">郵便番号と住所</div>
              <v-row>
                <v-col>
                  <v-text-field label="郵便番号*"
                                v-model="student.profile.postcode"
                                placeholder="1234567"
                                hint="ハイフンは入力しないでください"
                                :rules="[validationRules.required, validationRules.postcode]"
                  ></v-text-field>
                </v-col>
                <v-col class="d-flex align-center">
                  <v-btn small depressed color="info" @click="searchAddressFromPostcode()">郵便番号から住所を調べる</v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select label="県*"
                            v-model="student.profile.address.prefecture"
                            :items="['北海道','青森県','岩手県','宮城県','秋田県','山形県','福島県','茨城県',
                                     '栃木県','群馬県','埼玉県','千葉県','東京都','神奈川県','新潟県','富山県',
                                     '石川県','福井県','山梨県','長野県','岐阜県','静岡県','愛知県','三重県',
                                     '滋賀県','京都府','大阪府','兵庫県','奈良県','和歌山県','鳥取県','島根県',
                                     '岡山県','広島県','山口県','徳島県','香川県','愛媛県','高知県','福岡県',
                                     '佐賀県','長崎県','熊本県','大分県','宮崎県','鹿児島県','沖縄県']"
                            hide-details
                            :rules="[validationRules.required]"
                  ></v-select>
                </v-col>
                <v-col>
                  <v-text-field
                    label="市区町村*"
                    v-model="student.profile.address.citytown"
                    hide-details
                    :rules="[validationRules.required]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="住所1（番地）"
                    v-model="student.profile.address.other1"
                  ></v-text-field>
                  <v-text-field
                    label="住所2（建物・号室など）"
                    v-model="student.profile.address.other2"
                  ></v-text-field>
                </v-col>
              </v-row>
              <div class="subtitle-2 mt-8">未成年の方は保護者の方の電話番号・メールアドレスをご記入ください。</div>
              <div class="subtitle-1 mt-8">携帯電話番号</div>
              <v-row>
                <v-col>
                  <v-text-field label="携帯電話番号*"
                                placeholder="08012345678"
                                hint="半角でご入力ください"
                                v-model="student.profile.phone"
                                :rules="[validationRules.required, validationRules.number]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <div class="subtitle-1 mt-8">アカウント情報</div>
              <v-row>
                <v-col>
                  <v-text-field label="メールアドレス*"
                                placeholder="yamada.taro@example.com"
                                hint="半角でご入力ください"
                                v-model="student.account.email"
                                :rules="[validationRules.required, validationRules.email]"
                  ></v-text-field>
                  <v-text-field label="メールアドレス確認*"
                                placeholder="yamada.taro@example.com"
                                hint="確認のためメールアドレスを再度入力ください"
                                v-model="studentAccountEmailConfirm"
                                :rules="[validationRules.required, validationRules.emailConfirm]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field type="password"
                                label="パスワード*"
                                hint="8文字以上のパスワードを入力ください"
                                v-model="student.account.password"
                                :rules="[validationRules.required, validationRules.min(8)]"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field type="password"
                                label="パスワード確認*"
                                hint="確認のためパスワードを再度入力ください"
                                v-model="studentAccountPasswordConfirm"
                                :rules="[validationRules.required, validationRules.passwordConfirm]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-6 mb-8">
                <v-col>
                  <v-btn color="primary" rounded outlined width="100%" @click="goToNextStep">次へ</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-form ref="form_step2">
              <div class="title">保護者情報の入力</div>
              <div v-if="studentProfileAge && studentProfileAge >= env.studentNeedParentAge">
                <div class="caption">{{ env.studentNeedParentAge }}歳以上のため保護者情報の入力をしていただく必要はございません。</div>
              </div>
              <div v-else>
                <div class="caption">{{ env.studentNeedParentAge }}歳未満のため保護者情報を入力ください。</div>
                <div class="caption">*がついた項目は入力必須です</div>
                <div class="subtitle-1 mt-8">氏名</div>
                <v-row>
                  <v-col>
                    <v-text-field
                      label="名字*"
                      v-model="student.parent.profile.familyName"
                      placeholder="山田"
                      :rules="[validationRules.required]"
                    ></v-text-field>
                    <v-text-field
                      label="名字（カナ）*"
                      v-model="student.parent.profile.familyNameKana"
                      placeholder="ヤマダ"
                      hint="全角カナで入力ください"
                      :rules="[validationRules.required, validationRules.kana]"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="名前*"
                      v-model="student.parent.profile.firstName"
                      placeholder="一郎"
                      :rules="[validationRules.required]"
                    ></v-text-field>
                    <v-text-field
                      label="名前（カナ）*"
                      v-model="student.parent.profile.firstNameKana"
                      placeholder="イチロウ"
                      hint="全角カナで入力ください"
                      :rules="[validationRules.required, validationRules.kana]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <div class="subtitle-1 mt-8">性別と続柄</div>
                <v-row>
                  <v-col>
                    <v-select label="性別*"
                              v-model="student.parent.profile.gender"
                              :items="['男', '女', '無回答']"
                              :rules="[validationRules.required]"
                    ></v-select>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="生徒との続柄"
                      v-model="student.parent.profile.relationship"
                      placeholder="父"
                      :rules="[validationRules.required]"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <v-row class="mt-6 mb-8">
                <v-col>
                  <v-btn rounded width="100%" depressed @click="stepper.step -= 1">戻る</v-btn>
                </v-col>
                <v-col>
                  <v-btn color="primary" rounded outlined width="100%" @click="goToNextStep">次へ</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>
          <v-stepper-content step="3">
            <v-form ref="form_step3">
              <div class="title">利用規約への同意</div>
              <v-card outlined class="mt-8">
                <div class="terms">
                  <v-card-text>
利用規約<br/>
<br/>
この利用規約（以下，「本規約」といいます。）は，合同会社学生ギルド（以下，「当社」といいます。）がこのウェブサイト上で提供するサービス（以下，「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本サービスをご利用いただきます。<br/>
<br/>
第1条（適用）<br/>
1. 本規約は，ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。<br/>
2. 当社は本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下，「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。<br/>
3. 本規約の規定が前条の個別規定の規定と矛盾する場合には，個別規定において特段の定めなき限り，個別規定の規定が優先されるものとします。<br/>
<br/>
第2条（利用登録）<br/>
1. 本サービスにおいては，登録希望者が本規約に同意の上，当社の定める方法によって利用登録を申請し，当社がこれを承認することによって，利用登録が完了するものとします。<br/>
2. 当社は，利用登録の申請者に以下の事由があると判断した場合，利用登録の申請を承認しないことがあり，その理由については一切の開示義務を負わないものとします。<br/>
  1. 利用登録の申請に際して虚偽の事項を届け出た場合<br/>
  2. 本規約に違反したことがある者からの申請である場合<br/>
  3. その他，当社が利用登録を相当でないと判断した場合<br/>
<br/>
第3条（ユーザーIDおよびパスワードの管理）<br/>
1. ユーザーは，自己の責任において，本サービスのユーザーIDおよびパスワードを適切に管理するものとします。<br/>
2. ユーザーは，いかなる場合にも，ユーザーIDおよびパスワードを第三者に譲渡または貸与し，もしくは第三者と共用することはできません。当社は，ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には，そのユーザーIDを登録しているユーザー自身による利用とみなします。<br/>
3. ユーザーID及びパスワードが第三者によって使用されたことによって生じた損害は，当社に故意又は重大な過失がある場合を除き，当社は一切の責任を負わないものとします。<br/>
<br/>
第4条（利用料金および支払方法）<br/>
1. ユーザーは，本サービスの有料部分の対価として，当社が別途定め，本ウェブサイトに表示する利用料金を，当社が指定する方法により支払うものとします。<br/>
2. ユーザーが利用料金の支払を遅滞した場合には，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止することができるものとします。<br/>
<br/>
第5条（禁止事項）<br/>
ユーザーは，本サービスの利用にあたり，以下の行為をしてはなりません。<br/>
1. 法令または公序良俗に違反する行為<br/>
2. 犯罪行為に関連する行為<br/>
3. 本サービスの内容等，本サービスに含まれる著作権，商標権ほか知的財産権を侵害する行為<br/>
4. 当社，ほかのユーザー，またはその他第三者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為<br/>
5. 本サービスによって得られた情報を商業的に利用する行為<br/>
6. 当社のサービスの運営を妨害するおそれのある行為<br/>
7. 不正アクセスをし，またはこれを試みる行為<br/>
8. 他のユーザーに関する個人情報等を収集または蓄積する行為<br/>
9. 不正な目的を持って本サービスを利用する行為<br/>
10. 本サービスの他のユーザーまたはその他の第三者に不利益，損害，不快感を与える行為<br/>
11. 他のユーザーに成りすます行為<br/>
12. 当社が許諾しない本サービス上での宣伝，広告，勧誘，または営業行為<br/>
13. 面識のない異性との出会いを目的とした行為<br/>
14. 当社のサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為<br/>
15. その他，当社が不適切と判断する行為<br/>
<br/>
第6条（本サービスの提供の停止等）<br/>
1. 当社は，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。<br/>
  1. 本サービスにかかるコンピュータシステムの保守点検または更新を行う場合<br/>
  2. 地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合<br/>
  3. コンピュータまたは通信回線等が事故により停止した場合<br/>
  4. その他，当社が本サービスの提供が困難と判断した場合<br/>
2. 当社は，本サービスの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害についても，一切の責任を負わないものとします。<br/>
<br/>
第7条（利用制限および登録抹消）<br/>
1. 当社は，ユーザーが以下のいずれかに該当する場合には，事前の通知なく，ユーザーに対して，本サービスの全部もしくは一部の利用を制限し，またはユーザーとしての登録を抹消することができるものとします。<br/>
  1. 本規約のいずれかの条項に違反した場合<br/>
  2. 登録事項に虚偽の事実があることが判明した場合<br/>
  3. 料金等の支払債務の不履行があった場合<br/>
  4. 当社からの連絡に対し，一定期間返答がない場合<br/>
  5. 本サービスについて，最終の利用から一定期間利用がない場合<br/>
  6. その他，当社が本サービスの利用を適当でないと判断した場合<br/>
2. 当社は，本条に基づき当社が行った行為によりユーザーに生じた損害について，一切の責任を負いません。<br/>
<br/>
第8条（退会）<br/>
ユーザーは，当社の定める退会手続により，本サービスから退会できるものとします。<br/>
<br/>
第9条（保証の否認および免責事項）<br/>
1. 当社は，本サービスに事実上または法律上の瑕疵（安全性，信頼性，正確性，完全性，有効性，特定の目的への適合性，セキュリティなどに関する欠陥，エラーやバグ，権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。<br/>
2. 当社は，本サービスに起因してユーザーに生じたあらゆる損害について一切の責任を負いません。ただし，本サービスに関する当社とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合，この免責規定は適用されません。<br/>
3. 前項ただし書に定める場合であっても，当社は，当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（当社またはユーザーが損害発生につき予見し，または予見し得た場合を含みます。）について一切の責任を負いません。また，当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害の賠償は，ユーザーから当該損害が発生した月に受領した利用料の額を上限とします。<br/>
4. 当社は，本サービスに関して，ユーザーと他のユーザーまたは第三者との間において生じた取引，連絡または紛争等について一切責任を負いません。<br/>
<br/>
第10条（サービス内容の変更等）<br/>
当社は，ユーザーに通知することなく，本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし，これによってユーザーに生じた損害について一切の責任を負いません。<br/>
<br/>
第11条（利用規約の変更）<br/>
当社は，必要と判断した場合には，ユーザーに通知することなくいつでも本規約を変更することができるものとします。なお，本規約の変更後，本サービスの利用を開始した場合には，当該ユーザーは変更後の規約に同意したものとみなします。<br/>
第12条（個人情報の取扱い）<br/>
当社は，本サービスの利用によって取得する個人情報については，当社「プライバシーポリシー」に従い適切に取り扱うものとします。<br/>
<br/>
第13条（通知または連絡）<br/>
ユーザーと当社との間の通知または連絡は，当社の定める方法によって行うものとします。当社は,ユーザーから,当社が別途定める方式に従った変更届け出がない限り,現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い,これらは,発信時にユーザーへ到達したものとみなします。<br/>
第14条（権利義務の譲渡の禁止）<br/>
ユーザーは，当社の書面による事前の承諾なく，利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し，または担保に供することはできません。<br/>
<br/>
第15条（準拠法・裁判管轄）<br/>
1. 本規約の解釈にあたっては，日本法を準拠法とします。<br/>
2. 本サービスに関して紛争が生じた場合には，当社の本店所在地を管轄する裁判所を専属的合意管轄とします。<br/>
以上<br/>
                  </v-card-text>
                </div>
              </v-card>
              <v-checkbox label="利用規約に同意する"
                          class="ml-4"
                          v-model="studentAgreeTerms"
                          :rules="[validationRules.requiredAgree]"
              ></v-checkbox>
              <v-row class="mt-6 mb-8">
                <v-col>
                  <v-btn rounded width="100%" depressed @click="stepper.step -= 1">戻る</v-btn>
                </v-col>
                <v-col>
                  <v-btn color="primary" rounded outlined width="100%" @click="goToNextStep">次へ</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>
          <v-stepper-content step="4">
            <div class="title">ご確認</div>
            <div class="subtitle-1 mt-8">会員情報</div>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                <tr>
                  <td width="40%">氏名</td>
                  <td>{{ student.profile.familyName }} {{ student.profile.firstName }}</td>
                </tr>
                <tr>
                  <td>氏名（カナ）</td>
                  <td>{{ student.profile.familyNameKana }} {{ student.profile.firstNameKana }}</td>
                </tr>
                <tr>
                  <td>生年月日</td>
                  <td>{{ studentProfileBirthDateFormatted }}（{{ studentProfileAge }}歳）</td>
                </tr>
                <tr>
                  <td>性別</td>
                  <td>{{ student.profile.gender }}</td>
                </tr>
                <tr>
                  <td>郵便番号</td>
                  <td>{{ student.profile.postcode }}</td>
                </tr>
                <tr>
                  <td>住所</td>
                  <td>{{ student.profile.address.prefecture }}{{ student.profile.address.citytown }}{{ student.profile.address.other1 }}{{ student.profile.address.other2 }}</td>
                </tr>
                <tr>
                  <td>メールアドレス</td>
                  <td>{{ student.account.email }}</td>
                </tr>
                <tr>
                  <td>パスワード</td>
                  <td>{{ !!student.account.password ? '********' : '' }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div class="subtitle-1 mt-8">保護者情報</div>
            <div v-if="studentProfileAge && studentProfileAge >= env.studentNeedParentAge">
              <div class="caption">{{ env.studentNeedParentAge }}歳以上のため保護者情報は登録されていません</div>
            </div>
            <v-simple-table v-else>
              <template v-slot:default>
                <tbody>
                <tr>
                  <td width="40%">氏名</td>
                  <td>{{ student.parent.profile.familyName }} {{ student.parent.profile.firstName }}</td>
                </tr>
                <tr>
                  <td>氏名（カナ）</td>
                  <td>{{ student.parent.profile.familyNameKana }} {{ student.parent.profile.firstNameKana }}</td>
                </tr>
                <tr>
                  <td>性別</td>
                  <td>{{ student.parent.profile.gender }}</td>
                </tr>
                <tr>
                  <td>続柄</td>
                  <td>{{ student.parent.profile.relationship }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div class="subtitle-1 mt-8">利用規約</div>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                <tr>
                  <td width="40%">利用規約への同意</td>
                  <td>{{ studentAgreeTerms ? "同意する" : "" }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div class="caption mt-8">お間違いがなければ、下の会員登録ボタンを押してください。</div>
            <v-row class="mt-6 mb-8">
              <v-col>
                <v-btn rounded width="100%" depressed @click="stepper.step -= 1">戻る</v-btn>
              </v-col>
              <v-col>
                <v-btn color="primary" rounded width="100%" @click="signUp"
                :loading="studentSigningUp"
                >会員登録</v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
  </v-container>
</template>
<script>
  import axios from 'axios'
  import validationRules from '../../utils/validationRules'
  export default {
    data () {
      return {
        /*
        student: {
          profile: {
            familyName: "山田",
            firstName: "太郎",
            familyNameKana: "ヤマダ",
            firstNameKana: "タロウ",
            birthDate: "2008-12-24",
            gender: "男",
            postcode: "4710028",
            address: {
              prefecture: "愛知県",
              citytown: "豊田市神明町",
              other1: "1丁目24",
              other2: ""
            },
            phone: "09012345678",
          },
          parent: {
            profile: {
              familyName: "山田",
              firstName: "一郎",
              familyNameKana: "ヤマダ",
              firstNameKana: "イチロウ",
              relationship: "父",
              gender: "男"
            }
          },
          account: {
            email: "ayahito_saji@outlook.jp",
            password: "yamadayamada",
          }
        },
        studentAccountEmailConfirm: "ayahito_saji@outlook.jp",
        studentAccountPasswordConfirm: "yamadayamada",
        studentAgreeTerms: true,
        */
        student: {
          profile: {
            familyName: "",
            firstName: "",
            familyNameKana: "",
            firstNameKana: "",
            birthDate: "",
            gender: "",
            postcode: "",
            address: {
              prefecture: "",
              citytown: "",
              other1: "",
              other2: ""
            },
            phone: "",
          },
          parent: {
            profile: {
              familyName: "",
              firstName: "",
              familyNameKana: "",
              firstNameKana: "",
              relationship: "",
              gender: ""
            }
          },
          account: {
            email: "",
            password: "",
          }
        },
        studentAccountEmailConfirm: "",
        studentAccountPasswordConfirm: "",
        studentAgreeTerms: false,

        studentSigningUp: false,
        stepper: {
          step: 1,
        },
        studentProfileBirthDateMenu: {
          show: false
        },
        cacheSearchAddressFromPostcode: {},
        validationRules: Object.assign({
          emailConfirm: value => value == this.student.account.email || '入力したメールアドレスと同じものを入力してください',
          passwordConfirm: value => value == this.student.account.password || '入力したパスワードと同じものを入力してください'
        }, validationRules),
        env: {
          studentNeedParentAge: Number(process.env.VUE_APP_STUDENT_NEED_PARENT_AGE)
        }
      }
    },
    computed: {
      studentProfileBirthDateFormatted: function () {
        if (!this.student.profile.birthDate) return ''
        let tmp = this.student.profile.birthDate.split("-")
        return `${tmp[0]}年${tmp[1]}月${tmp[2]}日`
      },
      studentProfileAge: function () {
        if (!this.student.profile.birthDate) return null
        let birthdayNumber = Number(this.student.profile.birthDate.split("-").join(""))
        let today = new Date()
        let todayNumber = Number(today.getFullYear().toString().padStart(4, '0') + (today.getMonth() + 1).toString().padStart(2, '0') + today.getDate().toString().padStart(2, '0'))
        return Math.floor((todayNumber - birthdayNumber) / 10000)
      }
    },
    methods: {
      searchAddressFromPostcode: function () {
        if (this.student.profile.postcode.length === 7) {
          if (typeof this.cacheSearchAddressFromPostcode[this.student.profile.postcode] !== "undefined") {
            console.log("GET ADDRESS FROM CACHE")
            const data = this.cacheSearchAddressFromPostcode[this.student.profile.postcode]
            if (data !== null) {
              this.student.profile.address.prefecture = data.prefecture
              this.student.profile.address.citytown = data.citytown
            }
          } else {
            console.log("GET ADDRESS FROM SERVER")
            axios.get(`https://apis.postcode-jp.com/api/v4/postcodes/${this.student.profile.postcode}`, {
              headers: {
                apikey: process.env.POSTCODEJP_API_KEY
              }
            }).then((res) => {
              const data = res.data[0]
              this.$set(this.cacheSearchAddressFromPostcode, this.student.profile.postcode, {
                prefecture: data.pref,
                citytown: data.city + data.town
              })
              this.student.profile.address.prefecture = data.pref
              this.student.profile.address.citytown = data.city + data.town
            }).catch(() => {
              this.$set(this.cacheSearchAddressFromPostcode, this.student.profile.postcode, null)
            })
          }
        }
      },
      goToNextStep: function () {
        if (this.stepper.step == 1) {
          if (this.$refs.form_step1.validate())
            this.stepper.step ++
        } else if (this.stepper.step == 2) {
          if (this.studentProfileAge && this.studentProfileAge >= this.env.studentNeedParentAge || this.$refs.form_step2.validate())
            this.stepper.step ++
        } else if (this.stepper.step == 3) {
          if (this.$refs.form_step3.validate())
            this.stepper.step ++
        }
      },
      signUp: function () {
        this.studentSigningUp = true
        this.$store.dispatch("user/signUpAsStudent", this.student).then(() => {
          this.$router.push("/account")
        }).catch((errorCode) => {
          console.log("Error: ", errorCode)
        }).finally(() => {
          this.studentSigningUp = false
        })
      }
    },
    watch: {
      "studentProfileBirthDateMenu.show" (val) {
        val && setTimeout(() => (this.$refs.studentProfileBirthDatePicker.activePicker = 'YEAR'))
      },
    }
  }
</script>
<style scoped lang="scss">
  .terms {
    height: 200px;
    overflow-y: auto;
  }
</style>
