import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'


const defaultState = {
  auth: null,
  data: {},
  dataUnsubscribe: null,
  readonlyData: {},
  readonlyDataUnsubscribe: null,
  userEventsData: [],
  userEventsDataUnsubscribe: null,
  bills: [],
  fetched: false
}

export default {
  namespaced: true,
  state: JSON.parse(JSON.stringify(defaultState)),
  getters: {
    // user (state) {
    //   return state.fetched ? { auth: state.auth, data: state.data } : null
    // }
    loggedIn (state) {
      return state.fetched === true && state.auth !== null
    },
    userEvent: (state) => (eventId) => {
      return state.userEventsData.find((userEvent) => { return userEvent.eventId === eventId })
    }
  },
  mutations: {
    resetState (state) {
      Object.assign(state, JSON.parse(JSON.stringify(defaultState)))
    },
    dataUpdate (state, data) {
      state.data = Object.assign({}, data)
    },
    readonlyDataUpdate (state, readonlyData) {
      state.readonlyData = Object.assign({}, readonlyData)
    },
    userEventsDataUpdate (state, userEvents) {
      state.userEventsData = userEvents
    }
  },
  actions: {
    authState (context) {
      return new Promise((resolve, reject) => {
        console.log("AUTH STATE")

        if (context.state.fetched === true) { // すでにログイン情報をfetchしてたら終了
          resolve()
        }
        firebase.auth().onAuthStateChanged(auth => { // ログイン情報をfetchを試みた
          context.state.auth = auth
          if (auth !== null) { // ログイン情報があった
            firebase.firestore().collection("users").doc(auth.uid).get()
              .then((doc) => {
                if (doc.exists === true) { // ユーザーデータもあった
                  context.state.data = doc.data()
                  context.state.fetched = true
                  context.commit('notification/notify', { text: 'ログインしました', type: 'success' }, { root: true })
                  if (context.state.dataUnsubscribe === null)
                    context.state.dataUnsubscribe = firebase.firestore().collection("users").doc(auth.uid).onSnapshot((user) => { context.commit('dataUpdate', user.data()) })
                  if (context.state.readonlyDataUnsubscribe === null)
                    context.state.readonlyDataUnsubscribe = firebase.firestore().collection("userReadonlys").doc(auth.uid).onSnapshot((userReadonly) => { context.commit('readonlyDataUpdate', userReadonly.data()) })
                  if (context.state.userEventsDataUnsubscribe === null)
                    context.state.userEventsDataUnsubscribe = firebase.firestore().collection("userReadonlys").doc(auth.uid).collection("events").orderBy("endDatetime", "asc").startAt(new Date()).onSnapshot((querySnapshot) => {
                      let userEvents = [];
                      querySnapshot.forEach((doc) => {
                        userEvents.push(Object.assign({ eventId: doc.id }, doc.data()))
                      })
                      context.commit("userEventsDataUpdate", userEvents)
                    })
                  resolve()
                }
              })
              .catch((error) => {
                console.error("Error: ", error)
                context.commit('notification/notify', { text: 'ログインに失敗しました', type: 'error' }, { root: true })
                context.state.fetched = true
                resolve()
              })
          } else {
            context.state.fetched = true
            resolve()
          }
        })
      })
    },
    login (context, account) {
      return new Promise((resolve, reject) => {
        console.log("LOG IN")
        firebase.auth().signInWithEmailAndPassword(account.email, account.password)
        .then((auth) => {
          console.log("SUCCESS TO LOGIN: " + account.email)
          // context.commit('notification/notify', { text: 'ログインしました', type: 'success' }, { root: true })
          resolve(auth)
        })
        .catch((error) => {
          const errorCode = error.code
          console.log(errorCode)
          if (errorCode === "auth/wrong-password" || errorCode === "auth/user-not-found")
            context.commit('notification/notify', { text: 'メールアドレスとパスワードが違います', type: 'error' }, { root: true })
          else
            context.commit('notification/notify', { text: '予期しないエラーです。しばらくしてからもう一度お試しください。', type: 'error' }, { root: true })

          reject(errorCode)
        })
      })
    },
    sendResetPasswordEmail (context, account) {
      return new Promise((resolve, reject) => {
        console.log("SEND RESET PASSWORD EMAIL")
        firebase.auth().sendPasswordResetEmail(account.email)
        .then((auth) => {
          console.log("SUCCESS TO SEND RESET PASSWORD EMAIL: " + account.email)
          // console.log(user)
          resolve(auth)
        })
        .catch((error) => {
          const errorCode = error.code
          reject(errorCode)
        })
      })
    },
    resetPassword (context, payload) {
      return new Promise((resolve, reject) => {
        console.log("RESET PASSWORD")
        firebase.auth().verifyPasswordResetCode(payload.oobCode)
        .then((email) => {
          firebase.auth().confirmPasswordReset(payload.oobCode, payload.password)
          .then(() => {
            console.log("SUCCESS TO RESET PASSWORD: "+email)
            context.commit('notification/notify', { text: 'パスワードが変更されました', type: 'success' }, { root: true })
            // console.log(user)
            resolve()
          })
        })
        .catch((error) => {
          const errorCode = error
          if (errorCode === "auth/expired-action-code")
            context.commit('notification/notify', { text: '認証コードの期限が切れています。もう一度パスワード変更のメールを送信してください。', type: 'error' }, { root: true })
          else if (errorCode === "auth/invalid-action-code")
            context.commit('notification/notify', { text: '認証コードが間違っています。もう一度パスワード変更のメールを送信してください。', type: 'error' }, { root: true })
          else if (errorCode === "auth/weak-password")
            context.commit('notification/notify', { text: '新しいパスワードが弱過ぎます。別のパスワードを入力してください。', type: 'error' }, { root: true })
          else
            context.commit('notification/notify', { text: '予期しないエラーです。しばらくしてからもう一度お試しください。', type: 'error' }, { root: true })
          reject(errorCode)
        })
      })
    },
    signUpAsStudent (context, student) {
      return new Promise((resolve, reject) => {
        console.log("SIGN UP AS STUDENT")
        firebase.auth().createUserWithEmailAndPassword(student.account.email, student.account.password)
          .then((auth) => { // アカウントを作成できた場合
            console.log("SUCCESS TO LOGIN: " + student.account.email)
            // console.log(user)
            resolve(auth)
          })
          .catch((error) => {
            const errorCode = error.code
            if (errorCode === "auth/email-already-in-use") { // すでにアカウントが存在する場合は
              firebase.auth().signInWithEmailAndPassword(student.account.email, student.account.password)
                .then((auth) => {
                  console.log("SUCCESS TO LOGIN: " + student.account.email)
                  // console.log(user)
                  resolve(auth)
                })
                .catch((error) => {
                  if (error.code === "auth/wrong-password")
                    context.commit('notification/notify', { text: 'すでにこのメールアドレスは登録されています。パスワードを以前のものと同じにしてください。', type: 'error' }, { root: true })
                  else
                    context.commit('notification/notify', { text: '予期しないエラーです。しばらくしてからもう一度お試しください。', type: 'error' }, { root: true })
                  reject(error.code)
                })
            } else { // 他のエラーの場合はrejectする
              if (errorCode === "auth/weak-password")
                context.commit('notification/notify', { text: 'パスワードが弱過ぎます。別のパスワードを入力してください。', type: 'error' }, { root: true })
              else
                context.commit('notification/notify', { text: '予期しないエラーです。しばらくしてからもう一度お試しください。', type: 'error' }, { root: true })
              reject(errorCode)
            }
          })
      }).then((auth) => { // ユーザーデータを取ってきて，student情報がなければ作成する．あればすでに登録済みなので終わる
        return new Promise((resolve, reject) => {
          firebase.firestore().collection("users").doc(auth.user.uid).get()
            .then((doc) => {
              if (doc.exists === true && typeof doc.data().student !== 'undefined') { // ユーザーデータがあり，かつ既にstudent情報が登録されている
                context.commit('notification/notify', { text: 'すでにこのメールアドレスは生徒として登録されています。', type: 'error' }, { root: true })
                reject('auth/email-already-in-use-as-student')
              } else {
                resolve([auth, doc.data()])
              }
            }).catch(() => {
              context.commit('notification/notify', { text: '予期しないエラーです。開発者に連絡してください。', type: 'error' }, { root: true })
              reject(errorCode)
            })
          })
        }).then((args) => {
          const auth = args[0]
          const data = args[1]
          return new Promise((resolve, reject) => {
            const user = {
              student: {
                profile: student.profile,
                parent: student.parent,
              },
              email: student.account.email
            }
            firebase.firestore().collection("users").doc(auth.user.uid).set(user, { merge: true })
              .then(() => {
                if (context.state.dataUnsubscribe === null)
                  context.state.dataUnsubscribe = firebase.firestore().collection("users").doc(auth.user.uid).onSnapshot((user) => { context.commit('dataUpdate', user.data()) })
                if (context.state.readonlyDataUnsubscribe === null)
                  context.state.readonlyDataUnsubscribe = firebase.firestore().collection("userReadonlys").doc(auth.user.uid).onSnapshot((userReadonly) => { context.commit('readonlyDataUpdate', userReadonly.data()) })

                context.state.fetched = true
                resolve()
              })
              .catch((error) => {
                context.commit('notification/notify', { text: '予期しないエラーです。開発者に連絡してください。', type: 'error' }, { root: true })
                reject(error.code)
              })
          })
        })
    },
    logout (context) {
      return new Promise((resolve, reject) => {
        console.log("LOGOUT")
        if (context.state.dataUnsubscribe !== null) {
          context.state.dataUnsubscribe()
          context.state.dataUnsubscribe = null
        }
        if (context.state.readonlyDataUnsubscribe !== null) {
          context.state.readonlyDataUnsubscribe()
          context.state.readonlyDataUnsubscribe = null
        }
        if (context.state.userEventsDataUnsubscribe !== null) {
          context.state.userEventsDataUnsubscribe()
          context.state.userEventsDataUnsubscribe = null
        }
        firebase.auth().signOut().then(() => {
          context.commit("resetState")
          context.commit('notification/notify', { text: 'ログアウトしました', type: 'success' }, { root: true })
          resolve()
        }).catch(()=>{
          context.commit('notification/notify', { text: '予期しないエラーです。しばらくしてからもう一度お試しください。', type: 'error' }, { root: true })
          reject()
        })
      })
    },
    createCard (context, token) {
      return new Promise((resolve, reject) => {
        firebase.functions().httpsCallable('createCard')({
          token: token
        }).then((res) => {
          context.commit('notification/notify', { text: 'お支払い情報を登録しました。', type: 'success' }, { root: true })
          resolve()
        }).catch((error) => {
          console.log(error)
          context.commit('notification/notify', { text: 'お支払い情報を登録できませんでした。入力内容をご確認し、もう一度お試しください。', type: 'error' }, { root: true })        
          reject()
        })
      })
    },
    updateCard (context, token) {
      return new Promise((resolve, reject) => {
        firebase.functions().httpsCallable('updateCard')({
          token: token
        }).then((res) => {
          context.commit('notification/notify', { text: 'お支払い情報を更新しました。', type: 'success' }, { root: true })
          resolve()
        }).catch((error) => {
          console.log(error)
          context.commit('notification/notify', { text: 'お支払い情報を更新できませんでした。入力内容をご確認し、もう一度お試しください。', type: 'error' }, { root: true })        
          reject()
        })
      })
    },
    deleteCard (context) {
      return new Promise((resolve, reject) => {
        firebase.functions().httpsCallable('deleteCard')().then((res) => {
          context.commit('notification/notify', { text: 'お支払い情報を削除しました。', type: 'success' }, { root: true })
          resolve()
        }).catch((error) => {
          console.log(error)
          context.commit('notification/notify', { text: 'お支払い情報を削除できませんでした。しばらくしてからもう一度お試しください。', type: 'error' }, { root: true })        
          reject()
        })
      })
    }
  }
}
