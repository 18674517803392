import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'


const defaultState = {
	events: [],
	fetched: false
}

export default {
	namespaced: true,
	state: JSON.parse(JSON.stringify(defaultState)),
	getters: {
	},
	mutations: {
		resetState(state) {
			Object.assign(state, JSON.parse(JSON.stringify(defaultState)))
		},
	},
	actions: {
		fetchAll(context) {
			return new Promise((resolve, reject) => {
				console.log("AUTH STATE")

				if (context.state.fetched === true) { // すでにイベント情報をfetchしてたら終了
					resolve()
				}
				firebase.firestore().collection("events").orderBy("endDatetime", "asc").startAt(new Date()).get()
					.then((querySnapshot) => {
						let events = []
						querySnapshot.forEach(async (doc) => {
							const event = Object.assign({ id: doc.id }, await doc.data())
							events.push(event)
						})
						context.state.events = events
						context.state.fetched = true
						resolve()
					})
					.catch((error) => {
						console.error("Error: ", error)
						context.commit('notification/notify', { text: 'イベントの取得に失敗しました', type: 'error' }, { root: true })
						context.state.fetched = true
						resolve()
					})
			})
		},
    signUp (context, { eventId, token }) {
      return new Promise((resolve, reject) => {
        if (context.rootState.user.readonlyData.card) {
          console.log("カード登録済み", context.rootState.user.readonlyData.card)
          resolve()
        } else {
          console.log("カード登録なし")
          firebase.functions().httpsCallable('createCard')({
            token: token
          }).then((res) => {
            console.log("カード登録成功")
            resolve()
          }).catch((error) => {
            console.log(error)
            context.commit('notification/notify', { text: 'お支払い情報を登録できませんでした。お支払い情報をご確認し、もう一度お試しください。', type: 'error' }, { root: true })
            reject()
          })
        }
      }).then(() => {
        return new Promise((resolve, reject) => {
          console.log(eventId)
          firebase.functions().httpsCallable('applyEvent')({
            eventId: eventId
          }).then((res) => {
            context.commit('notification/notify', { text: 'お申し込みを完了いたしました。', type: 'success' }, { root: true })
            resolve()
          }).catch((error) => {
            console.log(error)
            context.commit('notification/notify', { text: 'お申し込みを完了できませんでした。入力内容をご確認し、もう一度お試しください。', type: 'error' }, { root: true })        
            reject()
          })
        })
      })
    },
    cancel (context, { eventId }) {
			return new Promise((resolve, reject) => {
				console.log(eventId)
				firebase.functions().httpsCallable('cancelEvent')({
					eventId: eventId
				}).then((res) => {
					context.commit('notification/notify', { text: 'キャンセルを完了いたしました。', type: 'success' }, { root: true })
					resolve()
				}).catch((error) => {
					console.log(error)
					context.commit('notification/notify', { text: 'キャンセルを完了できませんでした。入力内容をご確認し、もう一度お試しください。', type: 'error' }, { root: true })        
					reject()
				})
			})
    },
	}
}
