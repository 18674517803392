import nanoid from 'nanoid'

export default {
  namespaced: true,
  state: {
    notifications: []
  },
  mutations: {
    notify (state, { text, type, ms }) {
      state.notifications.push({
        nid: nanoid(),
        text: text,
        type: type,
        timer: setTimeout(() => { state.notifications.shift() }, ms ? ms : 6000)
      })
    }
  }
}