<template>
  <v-container class="fill-height justify-center">
    <v-card width="100%" max-width="640px" elevation="7" class="rounded-lg" v-if="sendedResetPasswordEmail === false">
      <v-card-title>パスワードの再設定</v-card-title>
      <v-card-subtitle>Reset Password</v-card-subtitle>
      <v-card-text>
        パスワードをお忘れの場合は、登録されたメールアドレスを入力してください。<br/>
        登録されたパスワード宛に、パスワードを再設定するメールを送信いたします。
        <v-form ref="form_reset_password">
          <v-text-field label="メールアドレス"
                        v-model="account.email"
                        :rules="[validationRules.required, validationRules.email]"
          ></v-text-field>
          <v-btn color="primary" rounded width="100%" @click="sendResetPasswordEmail" :loading="sendingResetPasswordEmail">このアカウントのパスワードを再設定</v-btn>
        </v-form>
      </v-card-text>
      <v-card-text>
        <div>
          <router-link to="/login">ログインはこちら</router-link>
        </div>
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-card-title>パスワード再設定メールを送信しました</v-card-title>
      <v-card-subtitle>Reset Password</v-card-subtitle>
      <v-card-text>
        <strong>{{ account.email }}</strong>宛にパスワード再設定用のメールを送信いたしました。<br/>
        ご確認いただき、メールに記載されたURLからパスワードを変更してください。<br/>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import validationRules from '../../utils/validationRules'
export default {
  data () {
    return {
      account: {
        email: ""
      },
      sendingResetPasswordEmail: false,
      sendedResetPasswordEmail: false,
      validationRules: validationRules
    }
  },
  methods: {
    sendResetPasswordEmail: function () {
      if (this.$refs.form_reset_password.validate()) {
        this.sendingResetPasswordEmail = true
        this.$store.dispatch("user/sendResetPasswordEmail", this.account).then(() => {
          console.log("SENDED RESET PASSWORD EMAIL")
          this.sendedResetPasswordEmail = true
        }).catch((errorCode) => {
          console.log("Error: ", errorCode)
        }).finally(() => {
            this.sendingResetPasswordEmail = false
        })
      }
    }
  }
}
</script>
