import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'


const defaultState = {
  data: null,
  fetched: false
}

export default {
  namespaced: true,
  state: JSON.parse(JSON.stringify(defaultState)),
  getters: {
    // user (state) {
    //   return state.fetched ? { auth: state.auth, data: state.data } : null
    // }
  },
  mutations: {
    resetState (state) {
      Object.assign(state, JSON.parse(JSON.stringify(defaultState)))
    },
    dataUpdate (state, data) {
      state.data = Object.assign({}, state.data, data)
    }
  },
  actions: {
    signUp (context, { constantSchool, token }) {
      return new Promise((resolve, reject) => {
        if (context.rootState.user.readonlyData.card) {
          console.log("カード登録済み", context.rootState.user.readonlyData.card)
          resolve()
        } else {
          console.log("カード登録なし")
          firebase.functions().httpsCallable('createCard')({
            token: token
          }).then((res) => {
            console.log("カード登録成功")
            resolve()
          }).catch((error) => {
            console.log(error)
            context.commit('notification/notify', { text: 'お支払い情報を登録できませんでした。お支払い情報をご確認し、もう一度お試しください。', type: 'error' }, { root: true })
            reject()
          })
        }
      }).then(() => {
        return new Promise((resolve, reject) => {
          console.log(constantSchool)
          firebase.functions().httpsCallable('constantSchoolCourseSignUp')({
            constantSchool: constantSchool
          }).then((res) => {
            context.commit('notification/notify', { text: 'お申し込みを完了いたしました。', type: 'success' }, { root: true })
            resolve()
          }).catch((error) => {
            console.log(error)
            context.commit('notification/notify', { text: 'お申し込みを完了できませんでした。入力内容をご確認し、もう一度お試しください。', type: 'error' }, { root: true })        
            reject()
          })
        })
      })
    },
  }
}
