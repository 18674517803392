<template>
  <v-container>
    <v-card class="py-4 my-8 rounded-lg">
      <v-card-title>特定商取引法に基づく表記</v-card-title>
      <v-container fluid>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr v-for="item in items" :key="item.name">
                <td>{{ item.name }}</td>
                <td v-html="item.element"></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-container>
    </v-card>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      items: [
        {
          name: "サービス名",
          element: "STREAM",
        },
        {
          name: "販売事業者名",
          element: "合同会社 学生ギルド",
        },
        {
          name: "代表者名",
          element: "鈴木 美苗",
        },
        {
          name: "所在地",
          element: "〒471-0028<br>愛知県豊田市神明町１丁目２４",
        },
        {
          name: "お問い合わせ",
          element:
            "<a href='mailto:contact@gakuseiguild.co.jp'>contact@gakuseiguild.co.jp</a> からお問い合わせください。<br>※弊社電話番号はお客様からのご要望に基づき遅滞なく電子メールにて通知いたします。弊社電話番号が必要なお客様は上記メールよりお申し出ください。",
        },
        {
          name: "販売価格",
          element:
            "購入手続きの際に画面に表示されます。<br>消費税は内税として表示しております。",
        },
        {
          name: "販売価格以外でお客様に発生する金銭",
          element:
            "当サイトのページの閲覧、コンテンツ購入、ソフトウェアのダウンロード等に必要となるインターネット接続料金、通信料金は、お客様のご負担となります。<br>また銀行口座振り込みの場合に発生する手数料もお客様のご負担となります。",
        },
        {
          name: "お支払方法",
          element:
            "以下のいずれかのお支払い方法をご利用いただけます。<li>各種クレジットカード</li><li>銀行口座振り込み</li>",
        },
        {
          name: "商品購入方法",
          element:
            "STREAMの公式ホームページより説明会にご参加いただき説明会で入会に導入いただいた後、入会フォームを埋めることで入会可能です。",
        },
        {
          name: "デジタルアイテム等の利用が可能となる時期",
          element:
            "特別な定めを置いている場合を除き、お支払い手続き完了後直ちにご利用いただけます。",
        },
        {
          name: "動作環境について",
          element:
            "OS    Windows10、MacOS Yosemite、Ubuntu18.04 LTS以上<br>CPU    Intel Core i5 dual core 2.0GHz以上<br>必要メモリ  8GB以上<br>必要ディスク容量  64GB以上<br>必要デバイス   WEBカメラ・マイク・マウス",
        },
        {
          name: "キャンセルについて",
          element:
            "<ol><li>お客様のご都合によるキャンセル</li>月額でお支払いいただいた場合は授業回ごとの返金はございません。<li>サービスの瑕疵に基づく返品（キャンセル）</li>サービスの瑕疵が発見されたときは、瑕疵を補修したサービスをアプリ教材のバージョンアップ又はその他適切な方法で提供いたします。<li>その他、オンライン商材についての規定に従うこととします。</li></ol>",
        },
        {
          name: "特別条件",
          element:
            "<ol><li>クーリングオフについて</li>特定商取引法に規定されているクーリングオフが適用されるサービスではありません。<li>定期課金方式の注意事項</li>契約期間途中の解約となった場合も契約満了日までの料金が発生し、日割精算等による返金を含めた一切の返金は行われません。この場合、サービスも契約満了日まで提供されます。</ol>",
        },
      ],
    };
  },
};
</script>