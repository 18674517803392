<template>
  <v-container class="fill-height justify-center">
    <v-card width="100%" max-width="320px" elevation="7" class="rounded-lg">
      <v-card-title>ログイン画面</v-card-title>
      <v-card-subtitle>Login</v-card-subtitle>
      <v-card-text>
        <v-form ref="form_login">
          <v-text-field label="メールアドレス"
                        v-model="account.email"
                        :rules="[validationRules.required, validationRules.email]"
          ></v-text-field>
          <v-text-field label="パスワード"
                        v-model="account.password"
                        type="password"
                        :rules="[validationRules.required]"
          ></v-text-field>
          <v-btn color="primary" rounded width="100%" @click="login" :loading="loggingIn">ログイン</v-btn>
        </v-form>
      </v-card-text>
      <v-card-text>
        <div>
          <router-link to="/login/forgot_password">パスワードをお忘れの方はこちら</router-link>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import validationRules from '../../utils/validationRules'
export default {
  data () {
    return {
      account: {
        email: "",
        password: "",
      },
      loggingIn: false,
      validationRules: validationRules
    }
  },
  methods: {
    login: function () {
      if (this.$refs.form_login.validate()) {
        this.loggingIn = true

        const redirect = this.$route.query.redirect
        this.$store.dispatch("user/login", this.account).then(() => {
          if (redirect) {
            this.$router.push(redirect)
          }
          else if (this.$route.path !== '/account'){
            this.$router.push("/account")
          }
        }).catch((errorCode) => {
          console.log("Error: ", errorCode)
        }).finally(() => {
          this.loggingIn = false
        })
      }
    }
  }
}
</script>
