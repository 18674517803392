export default {
  required: value => !!value || '入力必須項目です',
  requiredAgree: value => !!value || '同意が必要です',
  min: min => value => value.length >= min || `${min}文字以上で入力してください`,
  max: max => value => value.length <= max || `${max}文字以内で入力してください`,
  minmax: (min, max) => value => value.length >= min && value.length <= max || `${min}文字以上${max}文字以内で入力してください`,
  max: max => value => value.length <= max || `${max}文字以内で入力してください`,
  email: value => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return pattern.test(value) || '正しいメールアドレスを入力してください'
  },
  postcode: value => /^\d{7}$/.test(value) || '7桁の半角数字で入力してください',
  number: value => /^\d+$/.test(value) || '半角数字で入力してください',
  kana: value => !!/^[ァ-ヶー　]*$/.test(value) || '全角カナで入力してください',
}