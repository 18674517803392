<template>
  <div class="page">
    <div class="title-view">
      <div class="title-view-inner">
        <div class="title">
          <div class="flex-grow-1">
            <img src="@/assets/copy-white.png" class="headline-copy" />
            <img src="@/assets/points.png" class="headline-points" />
          </div>
        </div>
      </div>
    </div>
    <div class="recommend-comment">
      <v-card elevation="3" class="rounded-lg">
        <div class="d-flex py-1">
          <div class="d-flex justify-center align-center pl-1 flex-grow-1">
            <v-avatar>
              <v-img src="@/assets/prof_fujita.jpg"></v-img>
            </v-avatar>
          </div>
          <div class="flex-grow-0 text-left pr-1 ml-2">
            <div class="recommend-comment-subtitle">奈良女子大学工学部</div>
            <div class="recommend-comment-title primary--text">
              藤田盟児教授 推薦！
            </div>
            <div class="text-right mt-2">
              <v-btn
                outlined
                small
                color="primary"
                @click="fujita_recommend_dialog = true"
              >
                詳細はこちら >>
              </v-btn>
            </div>
          </div>
        </div>
      </v-card>
    </div>
    <div class="button">
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <v-btn
              class="white--text"
              filled
              block
              height="70px"
              rounded
              raised
              color="#06c755"
              @click="goToLINEMeeting"
            >
              <div>
                学習内容や進め方について相談できます！<br />
                <h2>
                  LINEで説明会に申し込む
                  <v-icon>mdi-chat-question</v-icon>
                </h2>
              </div>
            </v-btn>
          </v-col>
          <v-col cols="12" md="6">
            <v-btn
              filled
              block
              height="70px"
              rounded
              raised
              color="error"
              @click="goToInformationalMeeting"
            >
              <div>
                学習内容や進め方について相談できます！<br />
                <h2>
                  フォームで説明会に申し込む
                  <v-icon>mdi-form-select</v-icon>
                </h2>
              </div>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-dialog v-model="fujita_recommend_dialog" width="95%" max-width="960">
      <v-card>
        <v-img src="/pictures/prof_fujita_header.jpg" height="300" class="pa-4">
          <v-btn
            icon
            large
            class="white--text"
            @click="fujita_recommend_dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <div style="height: 100%" class="d-flex justify-center align-center">
            <h3>子供の未来はSTREAMから</h3>
          </div>
        </v-img>
        <v-card-text class="black--text mt-4">
          <p>奈良女子大学で建築の意匠と歴史を教えている藤田盟児と申します。</p>
          <p>
            今度、私は奈良女子大学に女子大初の工学部をつくる過程で、STREAM教育を取り入れました。<br />
            現在、社会はAIにデータ分析を任せるSociety5.0へ向かっています。だから、今後の教育は、AIにできないことを教えなくてはなりません。<br />
            今後、AIが何をできるようになるのかは不明ですが、AIに確実にできないことは創造だと思っています。
          </p>
          <p>
            なぜなら、AIは0と1を基盤にしたソフトウェアだからです。<br />
            もし、0から1を生み出すことが創造だとすれば、それはAIには考えられないことでしょう。<br />
            だから、今後は、創造力を身につけることが学習の中心になります。
          </p>
          <p>
            では、創造力は、どこから発揮されるでしょうか。<br />
            それは、芸術を長く考えてきた「美学」の中にヒントがあります。<br />
            美学では、創造は、人間がもっている抽象能力と、素材から受け取る感受性を、どこまで自由にできるかで発揮されると言います。<br />
            そこからARTが生まれるのだと。<br />
            だから、これまで科学技術を教えていたSTEM教育に、ARTの「A」が入ったSTREAM教育が生まれ、それが今の最先端の教育です。<br />
          </p>
          <p>
            では、抽象能力と感受性をどう扱えば新たなものが生まれるのかというと、建築の世界には「か・かた・かたち」という教えがあります。<br />
            感受性が働いて何かを発見することを「か」といい、それを抽象能力でイメージ化したものを「かた」と言う。それを技術をつかい素材に形を与えると「かたち」ができるという教えです。<br />
            新しいものの発端になる「か」は発見であり、既存の知識や技術、ものの捉え方から自由になれたときに生まれます。<br />
            それが、0から1が生まれることです。
          </p>
          <p>
            既存の知識や技術、ものの捉え方こそが情報ですから、それから自由になれる力はAIにはありません。<br />
            しかし、人間には既存のものから離れて創造する力があります。<br />
            美学では、それは無意識と身体の交流から生まれると言っています。<br />
            意識にある既存情報と合わないものを身体感覚を通して無意識が発見する。<br />
            これが、芸術的創造だというのです。
          </p>
          <p>
            だから、われわれは身体を動かして、そこで見つけた自身の違和感「か」から、新しいもの「かた」を発見し、「かたち」にするというトレーニングが必要です。<br />
            それには、新しいものを作る演習が一番だと考え、私は新しく作った奈良女子大学の工学部に、新たなものを作るPBL演習を必修科目としました。
          </p>
          <p>
            学生ギルドは、「作るを学ぼう」と言っています。<br />
            その通りだと思います。いまから人は「あたらしいものを作る」ことを学ばないといけないんです。<br />
            そして、それは既存の知識や技術、見方ではなく、自身が感覚的に感じた違和感「か」から、それを解決する方法としての「かた」を発見し、それを具体的な素材と技術で「かたち」にしていくことを学ぶことです。
          </p>
          <p>
            学生ギルドは「キミだけのワクワクを表現せよ」と言ってます。<br />
            自身が感覚的に感じた違和感こそが「キミだけのワクワク」です。それをかたちにするのが「表現」です。<br />
            ここに、学生ギルドの目標が、子供達の創造性を育成することであることが現れています。
          </p>
          <p>
            また、学生ギルドは、学生が指導者です。先生ではなく学生が教えるという方法にも共感します。<br />
            なぜなら、自分の違和感に気づき、正直に実行するには、それが許される温かい環境が必要だからです。<br />
            先生よりも先輩の方が、そうした環境をつくるのに有利ではないかと思います。
          </p>
          <p>
            ゆるい環境を与えられた子供は、困った子になるでしょう。でも、それを矯正して従わせれば創造力が発揮されない。それが今の教育界の苦悩です。<br />
            創造的な人材を輩出した松下村塾や適塾では、生徒を指導するのは先輩でした。吉田松陰や緒方洪庵は、それを見守り、声をかけるだけ。<br />
            そんな歴史上の成功例に近い環境ではないかと期待しています。
          </p>
          <p>
            Society5.0社会に必須のプログラミングを通して、ソフトウェアやロボットによって可能になる創造を体験する。<br />
            そんな体験をした子供達は、未来社会を自由に生きていく力を手に入れるのではないでしょうか。
          </p>
          <p>それが、私が学生ギルドを応援する理由です。</p>
          <v-divider />
          <div class="d-flex align-center mb-4 mt-4">
            <v-avatar size="60" class="mr-4">
              <v-img src="@/assets/prof_fujita.jpg"></v-img>
            </v-avatar>
            <h3>藤田盟児教授</h3>
          </div>
          <p>
            愛媛県今治市大三島町生まれ。<br />
            東京大学工学部建築学科卒業。<br />
            同大学院博士課程修了。<br />
            奈良文化財研究所、名古屋造形芸術大学、広島国際大学を経て、奈良女子大学教授。<br />
            文化庁文化審議会第2専門調査会委員、山口・兵庫・奈良県の文化財保護審議会委員。<br />
            専門分野は建築歴史・意匠。著書に『和室学』（共著、平凡社、2020)ほか多数。
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="features">
      <div class="features-content">
        <div class="features-headline">
          STREAMの特徴<br />
          <img src="@/assets/tri-down-black.svg" class="tri-down" />
        </div>
        <v-container>
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-card class="mx-auto" elevation="12" height="100%">
                <v-img height="200px" src="/pictures/4-ability.jpg"> </v-img>
                <v-card-title class="primary--text"
                  >作る楽しさを知ろう</v-card-title
                >
                <v-card-text class="text--primary">
                  <div>
                    プログラミングの文法を覚えたら、ゲームやアプリを作成できるのでしょうか。<br />
                    STREAMでは、プログラムのみならず、<strong>想像・探求・実装・表現</strong>を総合的に鍛えます。
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="3">
              <v-card class="mx-auto" elevation="12" height="100%">
                <v-img height="200px" src="/pictures/relationship.jpg"> </v-img>
                <v-card-title class="primary--text"
                  >学生講師と生徒という関係</v-card-title
                >
                <v-card-text class="text--primary">
                  <div>
                    友達同士の「横」の関係とも、先生と生徒の「縦」の関係とも違う、「<strong>斜め上</strong>」の関係性。
                    半歩先を行くものとして、皆さまに寄り添いながら学習を進めます。
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="3">
              <v-card class="mx-auto" elevation="12" height="100%">
                <v-img height="200px" src="/pictures/textbook.jpg"> </v-img>
                <v-card-title class="primary--text"
                  >対話形式の独自教材</v-card-title
                >
                <v-card-text class="text--primary">
                  <div>
                    STREAMでは、説明と質問からなる対話形式の独自の教材を開発し、<strong>スムーズな理解を徹底的にサポート</strong>します。
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="3">
              <v-card class="mx-auto" elevation="12" height="100%">
                <v-img height="200px" src="/pictures/community.jpg"> </v-img>
                <v-card-title class="primary--text"
                  >交流を支えるコミュニティ</v-card-title
                >
                <v-card-text class="text--primary">
                  <div>
                    円滑な学習には、講師や生徒が<strong>互いを認め合い、切磋琢磨する環境</strong>が重要です。<br />
                    STREAMでは、オンライン上にコミュニティを構築し、みんなで成果を発表したり、時にゲームをしたりして、親交を深めます。
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <div class="features">
      <div class="features-content">
        <div class="features-headline">
          カリキュラム<br />
          <img src="@/assets/tri-down-black.svg" class="tri-down" />
        </div>
        <v-container>
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-card class="mx-auto" elevation="12" height="100%">
                <v-img height="200px" src="/pictures/stage0.png"> </v-img>
                <v-card-title class="primary--text">STAGE 0</v-card-title>
                <v-card-subtitle class="pb-0"
                  >ビジュアルプログラミング</v-card-subtitle
                >
                <v-card-text class="text--primary">
                  <v-chip class="ma-1" small>mBlock</v-chip>
                  <v-chip class="ma-1" small>デザイン</v-chip>
                  <div>
                    プログラミングの導入としてmBlockを用いてゲームを作成します。
                    対話型の教材を用いて、プログラミングを全く知らない方でも見よう見まねで始めることができます。
                    「まず作る」ことで楽しさを感じていただき、モチベーションへとつなげます。
                    最終的に、自由に思い思いのゲームが作れることを目標とします。
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="3">
              <v-card class="mx-auto" elevation="12" height="100%">
                <v-img height="200px" src="/pictures/stage1.png"> </v-img>
                <v-card-title class="primary--text">STAGE 1</v-card-title>
                <v-card-subtitle class="pb-0"
                  >プログラミング基礎</v-card-subtitle
                >
                <v-card-text class="text--primary">
                  <v-chip class="ma-1" small>Python</v-chip>
                  <v-chip class="ma-1" small>アルゴリズム</v-chip>
                  <div>
                    Pythonの文法を学びます。
                    ブロックプログラミングとは異なり、実際に文字を打ってコーディングを行います。
                    バブルソートなどの基礎的なアルゴリズムを学び、論理的思考力を養います。
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="3">
              <v-card class="mx-auto" elevation="12" height="100%">
                <v-img height="200px" src="/pictures/stage2.png"> </v-img>
                <v-card-title class="primary--text">STAGE 2</v-card-title>
                <v-card-subtitle class="pb-0">ゲーム制作</v-card-subtitle>
                <v-card-text class="text--primary">
                  <v-chip class="ma-1" small>Python</v-chip>
                  <v-chip class="ma-1" small>デザイン</v-chip>
                  <div>
                    Pygameと呼ばれるライブラリを用いてゲーム制作に取り組みます。
                    アイデアを実現するために必要な機能や要素に分解し論理的に考える力や、実際に作りたいと思うゲームアイデアを実現するプログラミングスキルを身につけることができます
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="3">
              <v-card class="mx-auto" elevation="12" height="100%">
                <v-img height="200px" src="/pictures/stage3.png"> </v-img>
                <v-card-title class="primary--text">STAGE 3</v-card-title>
                <v-card-subtitle class="pb-0">AI、Web制作</v-card-subtitle>
                <v-card-text class="text--primary">
                  <v-chip class="ma-1" small>Python</v-chip>
                  <v-chip class="ma-1" small>JavaScript</v-chip>
                  <v-chip class="ma-1" small>デザイン</v-chip>
                  <div>
                    これまでに学んだスキルを実際に使うことにフォーカスします。
                    STREAMラボでは、Pythonを用いてゲームAIの開発を行います。
                    開発したプログラムを他人のAIと戦わせることができ、より良い動作を目指し研究を行います。
                    自分で課題を発見し様々なアプローチで問題の解決を目指します。
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <div class="features">
      <div class="features-content">
        <div class="features-headline">
          オンラインフィールドワーク<br />
          <img src="@/assets/tri-down-black.svg" class="tri-down" />
        </div>
        <v-container>
          <v-card elevation="12" class="rounded-lg">
            <v-container fluid>
              <v-row>
                <v-col cols="12" sm="8" class="order-last order-md-first">
                  <v-card-title class="primary--text">
                    身近なテクノロジーが「わかる」「できる」に
                  </v-card-title>
                  <v-card-text>
                    <p>
                      プログラミングは，みなさんが普段の生活で目にする様々なものに使われています．
                      <br />「信号機」，「自動ドア」などはもちろん，ゲームの中の物理演算や敵キャラクターの動きもプログラムによって書かれています．
                      <br />これらのプログラミングは難しいと思うかもしれませんが，実は<strong>意外とわかる・つくれる</strong>ものです．
                      <br />本教室のオンラインフィールドワークでは，<strong>「観察・調査」，「再現」</strong>の2ステップでこれを体感していただき，<strong>身近なものの仕組みを観察・想像する思考</strong>を育みます．
                      <br />さらに，できあがった作品を教室内で見せ合う，チューターがリアクション動画を投稿するなど，この取り組みを通した交流も盛んに行われています．
                      <br /><br />

                      これまでに実施したテーマ: 信号機，自動ドア，リモコン，クレーンゲーム
                    </p>
                  </v-card-text>
                </v-col>
                <v-col cols="12" sm="4">
                  <div class="text-center">
                    <v-avatar width="100%" height="100%" tile>
                      <v-img
                        src="/pictures/online_field_work.png"
                        height="100%"
                        style="border-radius: 8px"
                      />
                    </v-avatar>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-container>
      </div>
    </div>
    <div class="courses">
      <div class="courses-headline">
        受講料<br />
        <img src="@/assets/tri-down-black.svg" class="tri-down" />
      </div>
      <v-container>
        <div class="text-center red--text">
          <h1>初回体験無料！</h1>
        </div>
        <v-card elevation="12">
          <img src="@/assets/price_list.png" class="price-list" />
          <v-card-text class="text--primary">
            <div class="d-flex justify-center">
              <h5 class="grey--text text--darken-2">
                * お支払いはクレジットカード決済となります。<br />
                * 参加にはウェブカメラ、マイクが必要となります。
              </h5>
            </div>
          </v-card-text>
        </v-card>
      </v-container>
    </div>
    <div class="works-headline">
      受講生の作品紹介<br />
      <img src="@/assets/tri-down-black.svg" class="tri-down" />
    </div>
    <div class="works">
      <div class="works-content">
        <v-container>
          <v-carousel height="auto">
            <v-carousel-item>
              <v-sheet
                class="blue py-13 px-4"
                height="100%"
                style="background-image: url(/pictures/low-poly-mono.png)"
              >
                <v-row>
                  <v-col cols="12" md="6">
                    <div class="youtube-wrapper">
                      <iframe
                        class="youtube-frame"
                        src="https://www.youtube.com/embed/xeuURzf8bnE"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    class="d-flex flex-column align-center justify-center py-8"
                  >
                    <h3 class="works-content-title">
                      横スクロールアクションゲーム/mBlock
                    </h3>
                    <div class="works-content-subtitle">
                      ジャンプを駆使して<br />
                      コインを取りながらゴールを目指せ！
                    </div>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-carousel-item>
            <v-carousel-item>
              <v-sheet
                class="red py-13 px-4"
                height="100%"
                style="background-image: url(/pictures/low-poly-mono.png)"
              >
                <v-row>
                  <v-col cols="12" md="6">
                    <div class="youtube-wrapper">
                      <iframe
                        class="youtube-frame"
                        src="https://www.youtube.com/embed/uPsgghcgl50"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    class="d-flex flex-column align-center justify-center py-8"
                  >
                    <h3 class="works-content-title">
                      シューティングゲーム/mBlock
                    </h3>
                    <div class="works-content-subtitle">
                      飛行機に飛び乗って、<br />迫り来る敵を撃墜しよう！
                    </div>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-carousel-item>
            <v-carousel-item>
              <v-sheet
                class="green py-13 px-4"
                height="100%"
                style="background-image: url(/pictures/low-poly-mono.png)"
              >
                <v-row>
                  <v-col cols="12" md="6">
                    <div class="youtube-wrapper">
                      <iframe
                        class="youtube-frame"
                        src="https://www.youtube.com/embed/YFxeEQq_b80"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    class="d-flex flex-column align-center justify-center py-8"
                  >
                    <h3 class="works-content-title">爆弾マンゲーム/mBlock</h3>
                    <div class="works-content-subtitle">
                      十字に爆発する爆弾を配置して、<br />相手を追い詰めよう！
                    </div>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-carousel-item>
            <v-carousel-item>
              <v-sheet
                class="yellow darken-4 py-13 px-4"
                height="100%"
                style="background-image: url(/pictures/low-poly-mono.png)"
              >
                <v-row>
                  <v-col cols="12" md="6">
                    <div class="youtube-wrapper">
                      <iframe
                        class="youtube-frame"
                        src="https://www.youtube.com/embed/H2eX0Pzt-uA"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    class="d-flex flex-column align-center justify-center py-8"
                  >
                    <h3 class="works-content-title">
                      パクパクマンゲーム/mBlock
                    </h3>
                    <div class="works-content-subtitle">
                      敵に当たらないように、<br />全てのエサを食べ尽くせ！
                    </div>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
        </v-container>
      </div>
    </div>
    <div class="works-comments">
      <div class="works-comments-headline">
        受講生の声<br />
        <img src="@/assets/tri-down-black.svg" class="tri-down" />
      </div>
      <v-container>
        <v-card elevation="12" class="rounded-lg">
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="8" class="order-last order-md-first">
                <v-card-title class="primary--text">
                  「動くまで試行錯誤する。わくわくする学びの時間」
                </v-card-title>
                <v-card-text>
                  <p>
                    先生は、高専の学生先生。いきなりゲームを作り始めます。<br />
                    横で見ている私には、魔法の呪文を唱えているようにしか思えないのですが、<strong
                      >ゲームがどんどんできていきました。</strong
                    ><br />
                    <strong
                      >プログラミングは、正しく書く必要はない。動けば、それが正解。</strong
                    ><br />
                    <strong>動くまで、何度も試行錯誤する。</strong
                    >というのが、先生の教えでした。<br />
                    でも、これ、結構辛いことなんですよね。<br />
                    もちろん、独学でできる人もいるんだろうけど、<strong
                      >子どもにとっては傍にいて（物理的には凄く遠いけど）、
                      いっしょに笑ったり、考えたり、アイデアを出してくれたりする人が不可欠なんだな</strong
                    >と気付かされました。
                  </p>
                </v-card-text>
              </v-col>
              <v-col cols="12" sm="4">
                <div class="text-center">
                  <v-avatar width="100%" height="100%" tile>
                    <v-img
                      src="/pictures/comments.jpg"
                      height="100%"
                      style="border-radius: 8px"
                    />
                  </v-avatar>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-container>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      fujita_recommend_dialog: false,
    };
  },
  methods: {
    goToLINEMeeting: function () {
      location.href = "https://lin.ee/IHDShCu";
    },
    goToInformationalMeeting: function () {
      location.href = "https://forms.gle/Cxa7ERft9avAHAh66";
    },
  },
};
</script>
<style lang="scss">
p {
  margin-bottom: 1.5em !important;
}
h1 {
  margin-bottom: 1em !important;
}
.youtube-wrapper {
  position: relative;
  padding-top: 56.25%;
  .youtube-frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.page {
  background: #ffffff;
  width: 100%;
  .tri-down {
    margin: 1em 0;
  }
  .title-view {
    display: flex;
    flex-direction: column;
    background-image: url(/pictures/cover.jpg);
    background-size: cover;
    background-position: center bottom;
    padding: 200px 0 300px 0;
    height: calc(100vh - 60px);
    &-inner {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .title {
        text-align: left;
        width: 1100px;
        max-width: 90%;
        display: flex;
        color: #ffffff;
        .headline-copy {
          width: 700px;
          max-width: 100%;
        }
        .headline-points {
          margin-top: 1.5em;
          width: 600px;
          max-width: 100%;
        }
      }
    }
  }
  .recommend-comment {
    position: absolute;
    z-index: 2;
    top: 40px;
    right: 30px;
    &-subtitle {
      font-size: 14px;
      font-weight: 500;
    }
    &-title {
      font-size: 16px;
      font-weight: 800;
    }
  }
  .button {
    position: fixed;
    z-index: 2;
    width: 100%;
    // margin-top: -150px;
    bottom: 30px;
    &-subtitle {
      font-size: 1em;
    }
    &-title {
      font-size: 1.2em;
      font-weight: 800;
    }
  }
  .new-education {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    &-card {
      padding: 0.5em;
      &-title {
        font-size: 2em;
        font-weight: 800;
      }
      &-subtitle {
        font-size: 1.25em;
        font-weight: 800;
      }
      &-paragraph {
        font-size: 1em;
        margin-top: 1em;
      }
    }
    &-figure {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 1em;
      justify-content: center;
      &-row {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-grow: 0;
        justify-content: center;
      }
      &-col {
        width: 30%;
      }
      &-icon {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        color: #000000;
        font-weight: 800;
        background: #ffffff80;
      }
    }
    &-triangle-1 {
      position: absolute;
      transform: skewY(12.5deg);
      background: #ffffff;
      width: 100%;
      height: 300px;
    }
    &-triangle-2 {
      position: absolute;
      transform: skewY(-12.5deg);
      background: #ffffff;
      width: 100%;
      height: 300px;
    }
  }
  .ability-4 {
    background: #122530;
    color: #ffffff;
    &-headline {
      text-align: center;
      font-size: 1em;
      font-weight: 800;
      color: #000000;
      margin-top: 1em;
      padding-bottom: 1em;
    }
    &-content {
      position: relative;
      z-index: 1;
      padding: 3em 12px;
      &-title {
        font-size: 2em;
        font-weight: 800;
      }
      &-subtitle {
        font-size: 1.25em;
        font-weight: 800;
      }
      &-paragraph {
        font-size: 1em;
        margin-top: 1em;
      }
      &-description {
        background-color: #00000080;
        border-radius: 1em;
        box-shadow: 0 0 1em #00000040;
        padding: 1em;
        padding-top: 1.5em;
        padding-bottom: 0.5em;
        &-item {
          margin-bottom: 1em;
        }
        &-title {
          font-size: 1.25em;
          font-weight: 800;
        }
        &-subtitle {
          font-size: 0.7em;
          font-weight: 400;
          color: #c0c0c0;
        }
        &-paragraph {
          font-size: 1em;
          font-size: 0.85em;
        }
      }
    }
    &-triangle-1 {
      position: absolute;
      transform: skewY(12.5deg);
      background: #122530;
      width: 100%;
      height: 300px;
    }
    &-triangle-2 {
      position: absolute;
      transform: skewY(-12.5deg);
      background: #122530;
      width: 100%;
      height: 300px;
    }
    &-figure {
      position: relative;
      background: #122530;
      height: 250px;
      margin-bottom: 250px;
      &-background {
        position: absolute;
        transform: skewY(-12.5deg);
        background-size: cover;
        width: 100%;
        height: 500px;
        overflow: hidden;
        &-image {
          position: absolute;
          top: 50%;
          width: 100%;
          height: 200%;
          background-image: url(/pictures/pcdesk.jpg);
          background-size: cover;
          transform: skewY(12.5deg) translateY(-50%);
        }
      }
      &-iconsouter {
        position: relative;
        width: 100%;
      }
      &-icons {
        position: absolute;
        width: 500px;
        max-width: 100%;
        height: 500px;
        z-index: 1;
      }
    }
  }
  .features {
    position: relative;
    color: #000000;
    &-triangle {
      position: absolute;
      transform: skewY(-12.5deg);
      background: #ffffff;
      width: 100%;
      height: 250px;
    }
    &-headline {
      text-align: center;
      font-size: 1.5em;
      font-weight: 600;
      color: #000000;
      margin-top: 1em;
    }
    &-content {
      position: relative;
      padding-top: 5em;
      padding-bottom: 3em;
    }
    &-image {
      border-radius: 2px;
    }
    &-title {
      color: #ffab00;
      font-size: 1.2em;
      font-weight: 800;
      margin: 0.5em 0;
    }
    &-subtitle {
      font-size: 0.7em;
      font-weight: 400;
    }
    &-paragraph {
      font-size: 1em;
      font-size: 0.85em;
    }
  }
  .extra-features {
    background: #eff3f8;
    padding-top: 3em;
    &-headline {
      text-align: center;
      font-size: 1em;
      font-weight: 800;
      color: #000000;
      margin-top: 1em;
      margin-bottom: 1em;
    }
    &-slide {
      min-height: 40vw;
      border-radius: 1em;
      box-shadow: 0 0 0.5em #00000040;
      margin-bottom: 3em;
      padding: 1em;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &-header {
        margin-bottom: 1em;
      }
      &-title {
        font-size: 2em;
      }
      &-subtitle {
        font-size: 1.25em;
      }
    }
  }
  .works {
    // background: #122530;
    color: #ffffff;
    &-headline {
      padding-top: 3em;
      text-align: center;
      font-size: 1.5em;
      font-weight: 600;
      color: #000000;
      margin-top: 1em;
      padding-bottom: 1em;
    }
    &-content {
      position: relative;
      z-index: 1;
      padding: 3em 12px;
      &-title {
        font-size: 1.5em;
        font-weight: 800;
      }
      &-subtitle {
        font-size: 1.25em;
      }
      &-paragraph {
        font-size: 1em;
        margin-top: 1em;
      }
    }
    &-triangle-1 {
      position: absolute;
      transform: skewY(12.5deg);
      // background: #122530;
      width: 100%;
      height: 300px;
    }
    &-triangle-2 {
      position: absolute;
      transform: skewY(-12.5deg);
      // background: #122530;
      width: 100%;
      height: 300px;
    }
    &-comments {
      position: relative;
      min-height: 750px;
      &-headline {
        text-align: center;
        font-size: 1.5em;
        font-weight: 600;
        color: #000000;
        margin-top: 1em;
        padding-bottom: 1em;
      }
      &-background-color {
        position: absolute;
        margin-top: -200px;
        width: 100%;
        height: 350px;
        // background-color: #122530;
      }
      &-background {
        margin-top: -100px;
        position: absolute;
        transform: skewY(-12.5deg);
        background-size: cover;
        width: 100%;
        height: 700px;
        overflow: hidden;
        &-image {
          position: absolute;
          top: 50%;
          width: 100%;
          height: 200%;
          // background-image: url(/pictures/grass_pc.jpg);
          background-size: cover;
          background-position: center top;
          transform: skewY(12.5deg) translateY(-50%);
        }
      }
      &-card {
        position: relative;
        width: 100%;
        padding: 0.5em;
        &-title {
          font-size: 1.5em;
          font-weight: 800;
        }
        &-subtitle {
          font-size: 1.25em;
          font-weight: 800;
        }
        &-paragraph {
          font-size: 1em;
          margin-top: 1em;
        }
      }
      &-figure {
        width: 100%;
        height: 100%;
        display: flex;
        padding: 2em 0;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .courses {
    &-headline {
      text-align: center;
      font-size: 1.5em;
      font-weight: 600;
      color: #000000;
      margin-top: 1em;
      padding-bottom: 1em;
    }
    .price-list {
      width: 100%;
      max-width: 900px;
      display: block;
      margin: 0 auto;
    }
  }
}
</style>
