import Vue from 'vue'
import Vuex from 'vuex'

import user from './user'
import constantSchoolCourse from './constantSchoolCourse'
import notification from './notification'
import event from './event'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    user,
    constantSchoolCourse,
    notification,
    event
  }
})

export default store