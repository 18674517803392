import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from 'firebase'

import About from '../views/public/About.vue'
import SpecifiedCommercialTransactionAct from '../views/public/SpecifiedCommercialTransactionAct'

import StudentsSignUp from '../views/students/SignUp.vue'
import Login from '../views/users/Login.vue'
import ForgotPassword from '../views/users/ForgotPassword.vue'
import AuthAction from '../views/users/AuthAction.vue'
import Account from '../views/users/Account.vue'

import AccountSetting from '../views/users/AccountSetting.vue'

import ConstantSchoolSignUp from '../views/students/constant_school/SignUp.vue'

import Event from '../views/events/Info.vue'
import EventSignUp from '../views/events/SignUp.vue'
import EventCancel from '../views/events/Cancel.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'About',
    component: About,
    meta: { public: true }
  },
  {
    path: '/specified_commercial_transaction_act',
    name: 'SpecifiedCommercialTransactionAct',
    component: SpecifiedCommercialTransactionAct,
    meta: { public: true }
  },
  {
    path: '/students/signup',
    name: 'StudentsSignUp',
    component: StudentsSignUp,
    meta: { onlyNotLogin: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { onlyNotLogin: true }
  },
  {
    path: '/login/forgot_password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: { onlyNotLogin: true }
  },
  {
    path: '/auth/action',
    name: 'AuthAction',
    component: AuthAction,
    meta: { public: true }
  },
  {
    path: '/account',
    name: 'Account',
    component: Account
  },
  {
    path: '/account/setting',
    name: 'AccountSetting',
    component: AccountSetting
  },
  {
    path: '/constant_school/signup',
    name: 'ConstantSchoolSignUp',
    component: ConstantSchoolSignUp
  },
  {
    path: '/events/:event_id',
    name: 'Event',
    component: Event
  },
  {
    path: '/events/:event_id/payment',
    name: 'EventSignUp',
    component: EventSignUp
  },
  {
    path: '/events/:event_id/cancel',
    name: 'EventCancel',
    component: EventCancel
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.onlyNotLogin === true)) {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) next({ path: '/account' })
      else next()
    })
  } else if (to.matched.some(record => record.meta.public !== true)) {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) next()
      else next({ path: '/login', query: { redirect: to.fullPath } })
    })
  } else {
    next()
  }
})

export default router
