<template>
  <v-app>
    <v-app-bar
      app
      color="white"
      light
      elevate-on-scroll
    >
      <router-link to="/">
      <v-img src="@/assets/logo.png" class="shrink" contain width="120"/>
      </router-link>
      <v-spacer/>
      <div class="d-flex align-center">
        <div v-if="userFetched === true">
          <div v-if="Object.keys(userData).length === 0">
            <v-btn outlined color="primary" @click="login" class="mr-2">ログイン</v-btn>
            <v-btn depressed color="primary" @click="signUp" class="ml-2">会員登録</v-btn>
          </div>
          <div v-else>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  outlined
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ userData.student.profile.familyName }} {{ userData.student.profile.firstName }}さん
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="account">
                  <v-list-item-title>マイページ</v-list-item-title>
                </v-list-item>
                <v-list-item @click="accountSetting">
                  <v-list-item-title>アカウントの設定</v-list-item-title>
                </v-list-item>
                <v-list-item @click="logout">
                  <v-list-item-title>ログアウト</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>
    </v-app-bar>
    <notifications/>
    <v-main class="main">
      <transition name="fade" mode="out-in">
        <div v-if="userFetched === true" key="main" style="height: 100%;" class="d-flex flex-column">
          <div class="flex-grow-1">
            <transition name="short-fade" mode="out-in">
              <router-view/>
            </transition>
          </div>
          <div class="footer flex-grow-0">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  合同会社学生ギルド | <router-link to="/specified_commercial_transaction_act">特定商取引法に基づく表記</router-link><br/>
                </v-col>
                <v-col cols="12" sm="6">
                  合同会社 学生ギルド All rights reserved.
                </v-col>
                <v-col cols="12">
                  <div class="text-left mt-4">
                    <small>ご不明な点がございましたら、お気軽に<a href="mailto:contact@gakuseiguild.co.jp">contact@gakuseiguild.co.jp</a>までお問い合わせください。</small>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </div>
        <div class="loading" v-else key="loading">
          <img src="@/assets/logo.png" width="318" height="80"><br/>
          Presented by Gakusei Guild
        </div>
      </transition>
    </v-main>
  </v-app>
</template>
<script>
  import Notifications from './components/Notifications.vue'
  export default {
    components: {
      Notifications
    },
    computed: {
      userAuth: function () { return this.$store.state.user.auth },
      userData: function () { return this.$store.state.user.data },
      userFetched: function () { return this.$store.state.user.fetched }
    },
    // created () {
    //   console.log("POSTJP KEY", process.env.VUE_APP_POSTCODEJP_API_KEY)
    // },
    methods: {
      login: function () {
        this.$router.push("/login")
      },
      signUp: function () {
        this.$router.push("/students/signup")
      },
      account: function () {
        this.$router.push("/account")
      },
      accountSetting: function () {
        this.$router.push("/account/setting")
      },
      logout: function () {
        this.$store.dispatch("user/logout")
          .then(() => {
            this.$router.push("/")
          })
      }
    }
  }
</script>
<style lang="scss" scoped>
  .main {
    background: #EFF3F8;
  }
  .loading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  .short-fade-enter-active, .short-fade-leave-active {
    transition: opacity .1s;
  }
  .short-fade-enter, .short-fade-leave-to {
    opacity: 0;
  }
  .footer {
    position: relative;
    padding: 3em 0 10em;
    background-color: #122530;
    color: #ffffff;
    text-align: center;
    a {
      color: #ffffff;
      text-decoration: underline;
    }
    a:hover {
      color: #CEDCE7;
      text-decoration: none;
    }
  }
</style>
