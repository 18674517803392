<template>
  <v-container>
    <div style="width: 100%;max-width: 640px;" class="mx-auto">
      <v-card elevation="7" class="rounded-lg mb-6" style="padding: 10px">
        <v-list-item three-line>
          <v-list-item-content>
						<div class="text-h4 my-6">
						{{ event.name }}
						</div>
            <v-list-item-subtitle class="text-h6">
              {{ "日時: " + event.startDatetime.toDate().getFullYear() + "年" + (event.startDatetime.toDate().getMonth() + 1) + "月" + event.startDatetime.toDate().getDate() + "日 " + ('0' + event.startDatetime.toDate().getHours()).slice(-2)  + ":" + ('0' + event.startDatetime.toDate().getMinutes()).slice(-2) + " ~ " + (event.startDatetime.toDate().getFullYear() === event.endDatetime.toDate().getFullYear() ? "" : event.endDatetime.toDate().getFullYear() + "年") + (event.endDatetime.toDate().getMonth() + 1) + "月" + event.endDatetime.toDate().getDate() + "日 " + ('0' + event.endDatetime.toDate().getHours()).slice(-2)  + ":" + ('0' + event.endDatetime.toDate().getMinutes()).slice(-2) }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="text-h6">
              {{ "参加費: " + Math.floor(event.fee * (1 + $TAX_RATE)).toLocaleString() + "円（税込み）" }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="text-h6">
              {{ "申し込み締め切り: " + event.applyEndDatetime.toDate().getFullYear() + "年" + (event.applyEndDatetime.toDate().getMonth() + 1) + "月" + event.applyEndDatetime.toDate().getDate() + "日 " + ('0' + event.applyEndDatetime.toDate().getHours()).slice(-2)  + ":" + ('0' + event.applyEndDatetime.toDate().getMinutes()).slice(-2) + "まで"}}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="text-h6">
              {{ "キャンセル締め切り: " + event.cancelEndDatetime.toDate().getFullYear() + "年" + (event.cancelEndDatetime.toDate().getMonth() + 1) + "月" + event.cancelEndDatetime.toDate().getDate() + "日 " + ('0' + event.cancelEndDatetime.toDate().getHours()).slice(-2)  + ":" + ('0' + event.cancelEndDatetime.toDate().getMinutes()).slice(-2) + "まで"}}
            </v-list-item-subtitle>
						<div v-html="eventDescription" class="mt-6"></div>
          </v-list-item-content>
        </v-list-item>
        <v-card-actions class="justify-center">
          <v-btn width="100%" rounded color="#72AC57" dark @click="$router.push('/events/'+event.id+'/payment')" v-if="new Date() < event.applyEndDatetime.toDate() && (typeof(userEvent) === 'undefined' || userEvent.cancelDatetime !== null)">申し込む</v-btn>
          <v-btn width="100%" rounded color="error" dark @click="$router.push('/events/'+event.id+'/cancel')" v-else-if="typeof(userEvent) !== 'undefined' && userEvent.cancelDatetime === null && new Date() < event.cancelEndDatetime.toDate()">キャンセル</v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-container>
</template>
<script>
  import { marked } from "marked"

	marked.use({breaks: true})

  export default {
    data () {
      return {

      }
    },
    computed: {
      userAuth: function () { return this.$store.state.user.auth },
      userData: function () { return this.$store.state.user.data },
      userReadonlyData: function () { return this.$store.state.user.readonlyData },
      userFetched: function () { return this.$store.state.user.fetched },

      eventData: function () { return this.$store.state.event.events },
      eventFetched: function () { return this.$store.state.event.fetched },
      eventId: function () { return this.$route.params.event_id },
			event: function () { return this.eventData?.find((event) => { return this.eventId === event.id }) },
			eventDescription: function () { 
				return marked.parse(this.event.description.replaceAll("\\n", "\n")) },
			userEvent: function () { return this.$store.getters["user/userEvent"](this.eventId) }
		},
    methods: {
    }
  }
</script>
