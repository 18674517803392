<template>
  <v-container>
    <div style="width: 100%;max-width: 640px;" class="mx-auto">
      <v-card elevation="7" class="rounded-lg mb-6">
        <v-card-title>
          マイページ
        </v-card-title>
      </v-card>
      <v-card elevation="7" class="rounded-lg mb-6">
        <v-img dark height="64" src="/pictures/low-poly-mono.png" class="blue darken-2">
          <div class="d-flex flex-column">
            <v-spacer/>
            <v-card-title>STREAM プログラミング教室・英語教室</v-card-title>
          </div>
        </v-img>
        <div v-if="userReadonlyData && userReadonlyData.constantSchool"><!-- 継続プログラミング教室の生徒である //-->
          <v-card-text>
            受講申し込み手続きは完了しました。<br/>
            今後の詳細につきましてはメールでお伝えいたします。
          </v-card-text>
        </div>
        <div v-else><!-- 継続プログラミング教室の生徒ではない //-->
          <v-card-actions>
            <v-btn depressed dark x-large width="100%" color="blue" @click="$router.push('/constant_school/signup')">プログラミング教室または英語教室に申し込む</v-btn>
          </v-card-actions>
        </div>
      </v-card>
      <v-card elevation="7" class="rounded-lg mb-6">
        <v-img dark height="64" src="/pictures/low-poly-mono.png" class="green darken-2">
          <v-card-title>各種イベント</v-card-title>
        </v-img>
        <v-card-text>
          <v-list v-if="eventFetched === true && eventData.length > 0">
            <v-list-item v-for="event in eventData" :key="event.name">
              <v-card class="mx-auto mb-6" width="100%" elevation="7" style="padding: 10px">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="text-h5 mb-1">
                      {{ event.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ "日時: " + event.startDatetime.toDate().getFullYear() + "年" + (event.startDatetime.toDate().getMonth() + 1) + "月" + event.startDatetime.toDate().getDate() + "日 " + ('0' + event.startDatetime.toDate().getHours()).slice(-2)  + ":" + ('0' + event.startDatetime.toDate().getMinutes()).slice(-2) + " ~ " + (event.startDatetime.toDate().getFullYear() === event.endDatetime.toDate().getFullYear() ? "" : event.endDatetime.toDate().getFullYear() + "年") + (event.endDatetime.toDate().getMonth() + 1) + "月" + event.endDatetime.toDate().getDate() + "日 " + ('0' + event.endDatetime.toDate().getHours()).slice(-2)  + ":" + ('0' + event.endDatetime.toDate().getMinutes()).slice(-2) }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{ "参加費: " + Math.floor(event.fee * (1 + $TAX_RATE)).toLocaleString() + "円（税込み）" }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-card-actions class="justify-end">
                  <v-btn rounded color="#72AC57" dark @click="$router.push('/events/'+event.id)">詳細ページ</v-btn>
                  <v-btn rounded outlined color="#72AC57" light @click="$router.push('/events/'+event.id+'/payment')" v-if="new Date() < event.applyEndDatetime.toDate() && (typeof(userEvent(event.id)) === 'undefined' || userEvent(event.id).cancelDatetime !== null)">申し込む</v-btn>
                  <v-btn rounded color="error" dark @click="$router.push('/events/'+event.id+'/cancel')" v-else-if="typeof(userEvent(event.id)) !== 'undefined' && userEvent(event.id).cancelDatetime === null && new Date() < event.cancelEndDatetime.toDate()">キャンセル</v-btn>
                </v-card-actions>
                <v-list-item>
                  <v-list-item-subtitle class="text-right">
                    {{ "申し込み締め切り: " + event.applyEndDatetime.toDate().getFullYear() + "年" + (event.applyEndDatetime.toDate().getMonth() + 1) + "月" + event.applyEndDatetime.toDate().getDate() + "日 " + ('0' + event.applyEndDatetime.toDate().getHours()).slice(-2)  + ":" + ('0' + event.applyEndDatetime.toDate().getMinutes()).slice(-2) + "まで"}}
                  </v-list-item-subtitle>
                </v-list-item>
              </v-card>
            </v-list-item>
          </v-list>
          <div v-else-if="eventFetched === true">
            現在、申し込み可能なイベントはありません。
          </div>
          <div v-else>
            読み込み中です。
          </div>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>
<script>
  export default {
    data () {
      return {

      }
    },
    computed: {
      userAuth: function () { return this.$store.state.user.auth },
      userData: function () { return this.$store.state.user.data },
      userReadonlyData: function () { return this.$store.state.user.readonlyData },
      userFetched: function () { return this.$store.state.user.fetched },

      eventData: function () { return this.$store.state.event.events },
      eventFetched: function () { return this.$store.state.event.fetched },
      userEvent: function () { return function (eventId) { return this.$store.getters["user/userEvent"](eventId)} }
    },
    methods: {
      stream_constant_private_school () {
        location.href = "mailto:contact@gakuseiguild.co.jp";
      }
    }
  }
</script>
