<template>
  <div class="notifications">
    <transition-group mode="out-in">
      <div v-for="notification in notifications"
         :key="notification.nid"
         class="notification d-flex">
        <CheckCircleIcon class="success--text" v-if="notification.type == 'success'"></CheckCircleIcon>
        <InfoIcon class="info--text" v-if="notification.type == 'info'"></InfoIcon>
        <AlertCircleIcon class="warning--text" v-if="notification.type == 'warning'"></AlertCircleIcon>
        <XCircleIcon class="error--text" v-if="notification.type == 'error'"></XCircleIcon>
        <div class="pl-2">
          {{ notification.text }}
        </div>
      </div>
    </transition-group>
  </div>
</template>
<script>
  import {  CheckCircleIcon, InfoIcon, AlertCircleIcon, XCircleIcon } from 'vue-feather-icons'
  export default {
    components: {
      CheckCircleIcon,
      InfoIcon,
      AlertCircleIcon,
      XCircleIcon,
    },
    computed: {
      notifications: function () { return this.$store.state.notification.notifications },
    }
  }
</script>
<style scoped>
  .notifications {
    position: fixed;
    z-index: 1000;
    right: 16px;
    top: 80px;
    width: 320px;
    display: flex;
    flex-direction: column-reverse;
  }
  .notification {
    width: 320px;
    padding: 16px;
    margin-bottom: 16px;
    background: rgba(255, 255, 255, 0.95);
    border-radius: 4px;
    box-shadow: 0 10px 32px 0 rgba(30, 30, 30, 0.1);
    transition: all 0.5s;
  }
  .v-enter-active, .v-leave-active, .v-move {
    position: absolute;
  }
  .v-enter, .v-leave-to {
    opacity: 0;
    transform: translateX(16px);
  }
</style>