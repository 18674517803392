<template>
  <v-container>
    <div style="width: 100%;max-width: 640px;" class="mx-auto">
      <v-card elevation="7" class="rounded-lg mb-6">
        <v-card-title>アカウントの設定</v-card-title>
        <v-card-text v-if="student">
          <div class="subtitle-1 mt-8">生徒情報</div>
          <v-simple-table>
              <template v-slot:default>
                <tbody>
                <tr>
                  <td width="40%">氏名</td>
                  <td>{{ student.profile.familyName }} {{ student.profile.firstName }}（{{ student.profile.familyNameKana }} {{ student.profile.firstNameKana }}）</td>
                </tr>
                <tr>
                  <td>生年月日</td>
                  <td>{{ student.profile.birthDate }}（{{ studentProfileAge }}歳）</td>
                </tr>
                <tr>
                  <td>性別</td>
                  <td>{{ student.profile.gender }}</td>
                </tr>
                <tr>
                  <td>郵便番号</td>
                  <td>{{ student.profile.postcode }}</td>
                </tr>
                <tr>
                  <td>住所</td>
                  <td>{{ student.profile.address.prefecture }}{{ student.profile.address.citytown }}{{ student.profile.address.other1 }}{{ student.profile.address.other2 }}</td>
                </tr>
                <tr>
                  <td>メールアドレス</td>
                  <td>{{ userData.email }}</td>
                </tr>
                <tr>
                  <td>パスワード</td>
                  <td>********</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div class="subtitle-1 mt-8">保護者情報</div>
            <div v-if="studentProfileAge && studentProfileAge >= env.studentNeedParentAge" class="ma-4">
              {{ env.studentNeedParentAge }}歳以上のため保護者情報は登録されていません
            </div>
            <v-simple-table v-else>
              <template v-slot:default>
                <tbody>
                <tr>
                  <td width="40%">氏名</td>
                  <td>{{ student.parent.profile.familyName }} {{ student.parent.profile.firstName }}（{{ student.parent.profile.familyNameKana }} {{ student.parent.profile.firstNameKana }}）</td>
                </tr>
                <tr>
                  <td>性別</td>
                  <td>{{ student.parent.profile.gender }}</td>
                </tr>
                <tr>
                  <td>続柄</td>
                  <td>{{ student.parent.profile.relationship }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div class="subtitle-1 mt-8">お支払い情報</div>
            <v-alert type="info" dense outlined class="mt-2">信頼性と安全性の高い<a href="https://pay.jp" target="_blank">Pay.jp</a>を利用しています。</v-alert>
            <div v-if="UserReadonlyData.card">
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                <tr>
                  <td width="40%">カード情報</td>
                  <td>**** **** **** {{ UserReadonlyData.card.last4 }}</td>
                </tr>
                <tr>
                  <td>カード有効期限</td>
                  <td>{{ UserReadonlyData.card.exp_month }} / {{ UserReadonlyData.card.exp_year }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div class="d-flex">
              <PayJpArea text="カード情報の変更" @onTokenCreated="updateCard"/>
              <v-btn color="error" outlined class="ml-2" @click="deleteCard" :loading="deletingCard">カード情報の削除</v-btn>
            </div>
            </div>
            <div v-else class="ma-4">
              お支払い情報は登録されていません
              <PayJpArea text="カード情報の登録" @onTokenCreated="createCard"/>
            </div>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>
<script>
  import PayJpArea from '../../components/PayJpArea'
  export default {
    components: {
      PayJpArea
    },
    data () {
      return {
        deletingCard: false,
        env: {
          studentNeedParentAge: Number(process.env.VUE_APP_STUDENT_NEED_PARENT_AGE)
        }
      }
    },
    computed: {
      userAuth: function () { return this.$store.state.user.auth },
      userData: function () { console.log(this.$store.state.user.data);return this.$store.state.user.data },
      UserReadonlyData: function () { return this.$store.state.user.readonlyData },
      userFetched: function () { return this.$store.state.user.fetched },
      student: function () { return this.userData.student },
      studentProfileAge: function () {
        if (!this.student.profile.birthDate) return null
        let birthdayNumber = Number(this.student.profile.birthDate.split("-").join(""))
        let today = new Date()
        let todayNumber = Number(today.getFullYear().toString().padStart(4, '0') + (today.getMonth() + 1).toString().padStart(2, '0') + today.getDate().toString().padStart(2, '0'))
        return Math.floor((todayNumber - birthdayNumber) / 10000)
      }
    },
    methods: {
      createCard: function (token) {
        this.$store.dispatch("user/createCard", token).catch(() => {})
      },
      updateCard: function (token) {
        this.$store.dispatch("user/updateCard", token).catch(() => {})
      },
      deleteCard: function () {
        this.deletingCard = true
        this.$store.dispatch("user/deleteCard").catch(() => {}).finally(() => {
          this.deletingCard = false
        })
      }
    }
  }
</script>