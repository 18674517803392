<template>
  <div></div>
</template>
<script>
  export default {
    props: ['text'],
    data () {
      return {
        scriptElement: null
      }
    },
    mounted () {
      this.appendPayJpScriptTag()
      window.onTokenCreated = this.onTokenCreated
      window.onTokenFailed = this.onTokenFailed
    },
    beforeDestroy () {
      const payjpCheckoutIframeEl = document.getElementById('payjp-checkout-iframe')
      payjpCheckoutIframeEl.parentElement.remove()
      this.$el.removeChild(this.scriptElement)
      window.PayjpCheckout = null
      window.onTokenCreated = null
      window.onTokenFailed = null
    },
    methods: {
      appendPayJpScriptTag () {
        const attrs = {
          'id': 'payjp-script',
          'src': 'https://checkout.pay.jp/',
          'class': 'payjp-button',
          'data-key': process.env.VUE_APP_PAYJP_PUBLIC_KEY,
          'data-partial': true,
          'data-text': this.text || 'カード情報入力',
          'data-submit-text': '登録する',
          'data-lang': 'ja',
          'data-on-created': 'onTokenCreated',
          'data-on-failed': 'onTokenFailed',
          'data-name-placeholder': 'TARO YAMADA'
        }
        this.scriptElement = document.createElement('script')
        for (let key in attrs) {
          this.scriptElement.setAttribute(key, attrs[key])
        }
        this.$el.appendChild(this.scriptElement)
      },
      onTokenCreated: function () {
        const token = document.getElementsByName('payjp-token')[0].value
        this.$emit('onTokenCreated', token)
      },
      onTokenFailed: function (res) {
        this.$store.commit('notification/notify', { text: 'お支払い情報を登録できませんでした。入力内容をご確認し、もう一度お試しください。', type: 'error' }, { root: true })        
      },
    }
  }
</script>