<template>
  <v-container>
    <div style="width: 100%;max-width: 640px;" class="mx-auto" v-if="params.mode === 'resetPassword'">
      <v-card elevation="7" class="rounded-lg mb-6">
        <v-card-title>パスワードの再設定</v-card-title>
        <v-card-subtitle>Reset Password</v-card-subtitle>
        <v-card-text>
          <v-form ref="form_reset_password">
            <v-text-field label="新しいパスワード"
                          type="password"
                          v-model="resetPasswordDialog.account.password"
                          :rules="[validationRules.required, validationRules.min(8)]"
            ></v-text-field>
            <v-btn color="primary" rounded width="100%" @click="resetPassword()" :loading="resetPasswordDialog.loading">パスワードを更新</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>
<script>
import validationRules from '../../utils/validationRules'
export default {
  data () {
    return {
      params: {},
      resetPasswordDialog: {
        account: {
            password: ''
        },
        loading: false
      },
      validationRules: validationRules
    }
  },
  created: function () {
    this.params = location.search.substr(1).split('&').reduce((accumulator, currentValue) => {
      let keys = currentValue.split('=')
      return Object.assign(accumulator, {[keys[0]]: keys[1]})
    }, {})
  },
  methods: {
    resetPassword: function () {
      if (this.$refs.form_reset_password.validate()) {
        this.resetPasswordDialog.loading = true
        this.$store.dispatch("user/resetPassword", { password: this.resetPasswordDialog.account.password, oobCode: this.params.oobCode }).then(() => {
          this.$router.push("/login")
        }).catch((errorCode) => {
          console.log("Error: ", errorCode)
        }).finally(() => {
          this.resetPasswordDialog.loading = false
        })
      }
    }
  }
}
</script>